import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import Loader from '../Loader';
import Dialog from '../Dialog';
import classNames from 'classnames';
import Table from './nested/Table';
import './css/markers-dialog.css';

class MarkersDialog extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    isDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: true,
  };

  render() {
    const { data, onClose, onSave, isOpen, isDataLoading } = this.props;

    const dialogProps = {
      title: (
        <div className="markers-title-wrapper">
          <header className="markers-title-header text-ellipsis">{translator.getMessage('msg_marker_overrides')}</header>
        </div>
      ),
      actions: [
        <button key="markers-close-button" className="markers-button text-btn" onClick={onClose}>
          {translator.getMessage('msg_cancel')}
        </button>,
        <button key="markers-save-button" className="markers-button" onClick={onSave}>
          {translator.getMessage('msg_save')}
        </button>,
      ],
      onRequestClose: onClose,
      modalClassName: classNames({
        'markers-modal': true,
        'is-loading': isDataLoading,
      }),
      isOpen,
    };

    return <Dialog {...dialogProps}>{isDataLoading ? <Loader isVisible /> : <Table data={data} />}</Dialog>;
  }
}

export default MarkersDialog;
