import { ModelAbstract, CustomerOrderModel } from './';
import { AuthContext, PartnerContext } from '../Context';
import {
  CUSTOMIZE_PDF_OPTION_LIST,
  ESIG_ENVELOPE_TYPES,
  ESIG_DOCS_RESUBMIT_COMPATIBILITY_MAPPING,
  LEASE_OPTIONS,
  LEASE_OPTIONS_LIST,
  USER_ROLES,
  FINANCING_OPTIONS,
  PACKAGE_TYPE_IDS,
} from '../common/enums';
import { CALC_RESULT_PACKAGED, RESULT_TYPES } from './CalcResultsModel';
import dayjs from 'dayjs';

class ElectronicSignatureModel extends ModelAbstract {
  constructor(instance) {
    super(instance);

    this[ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER] = {
      subject: '',
      title: '',
      message: '',
      signerName: '',
      signerEmail: '',
      ccEmails: [],
      ccEmailsCheckbox: true,
      customDocument: null,
    };

    this[ESIG_ENVELOPE_TYPES.AMENDMENT] = {
      subject: '',
      title: '',
      message: '',
      signerName: '',
      signerEmail: '',
      ccEmails: [],
      ccEmailsCheckbox: true,
      customDocument: null,
    };

    this[ESIG_ENVELOPE_TYPES.CREDIT] = {
      subject: '',
      title: '',
      message: '',
      signerName: '',
      signerEmail: '',
      ccEmails: [],
      customDocument: null,
    };

    this[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT] = {
      subject: '',
      title: '',
      message: '',
      signerName: '',
      signerEmail: '',
      ccEmails: [],
      customDocument: null,
      creditCustomerSigningDate: '',
    };

    this.customizePdfOptionsMap = new CustomizePdfOptionsMap(this);
    this.customizePdfOptionsDisabledMap = new CustomizePdfOptionsDisabledMap(this);

    this.onChangePdfOption = this.onChangePdfOption.bind(this);
    this.getQueryString = this.getQueryString.bind(this);
    this.init = this.init.bind(this);
    this.onChangeRequestProps = this.onChangeRequestProps.bind(this);
    this.onChangeSignerEmail = this.onChangeSignerEmail.bind(this);
  }

  init(data, isAdditionalCredit = false) {
    const customerOrder = this.findSibling(d => d instanceof CustomerOrderModel);

    if (data && !isAdditionalCredit) {
      this.initFromDataValues(data);
    } else {
      this.initRequestDefaultValues(customerOrder, isAdditionalCredit);
    }
  }

  initRequestDefaultValues(customerOrder, isAdditionalCredit) {
    if (!isAdditionalCredit) {
      const creditEnvelopeSubject =
        [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_TWO].includes(customerOrder.packageIdSelected) &&
        customerOrder.financingOption === FINANCING_OPTIONS.RENTAL_GREATAMERICA
          ? 'Rental Agreement for Signature'
          : 'Sangoma Credit Documents for Signature';

      this[ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER] = {
        subject: 'Sangoma Quote Documents for Signature',
        ccEmailsCheckbox: true,
        ...this.requestDefaultValues(customerOrder),
      };

      this[ESIG_ENVELOPE_TYPES.AMENDMENT] = {
        subject: 'Sangoma Quote Documents for Signature',
        ccEmailsCheckbox: true,
        ...this.requestDefaultValues(customerOrder),
      };

      this[ESIG_ENVELOPE_TYPES.CREDIT] = {
        subject: creditEnvelopeSubject,
        ...this.requestDefaultValues(customerOrder, true),
      };
    } else {
      this[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT] = {
        subject: 'Additional Credit Documents',
        ...this.requestDefaultValues(customerOrder, true, isAdditionalCredit),
      };
    }

    const leaseOptions = LEASE_OPTIONS_LIST.filter(option => option !== LEASE_OPTIONS.NONE);
    this.customizePdfOptionsMap.showEstimatedLeasePayment = leaseOptions.includes(customerOrder.leaseOption);
  }

  requestDefaultValues(customerOrder, isCreditBundle = false, isAdditionalCredit = false) {
    const { serviceInfo, customerName, dealerContactEmail } = customerOrder;

    const message = isAdditionalCredit
      ? 'Please complete the following document(s). Let me know if you have any questions.'
      : 'Please sign this document. Let me know if you have any questions.';

    const values = {
      title: customerName,
      message: message,
      signerName:
        isCreditBundle || PartnerContext.model.isWhiteLabelWholesale
          ? ''
          : (serviceInfo.contactFirstName + ' ' + serviceInfo.contactLastName).trim(),
      signerEmail: isCreditBundle || PartnerContext.model.isWhiteLabelWholesale ? '' : serviceInfo.contactEmail,
      ccEmails: isCreditBundle ? [] : [dealerContactEmail],
      customDocument: null,
    };

    if (isAdditionalCredit) {
      values.creditCustomerSigningDate = this[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT].creditCustomerSigningDate;
    }

    return values;
  }

  initFromDataValues(data) {
    const { subject, title, ccEmails, customerSigner, message, eSignatureDocuments } = data;
    const ccEmailsCheckbox = ccEmails?.includes(AuthContext.model.email) || false;
    const customerOrder = this.findSibling(d => d instanceof CustomerOrderModel);
    const selectedDocs = [];

    for (let i = 0; i < eSignatureDocuments.length; i++) {
      const documentType =
        ESIG_DOCS_RESUBMIT_COMPATIBILITY_MAPPING[eSignatureDocuments[i].documentType] ||
        eSignatureDocuments[i].documentType;

      selectedDocs.push(documentType);
    }

    for (let i = 0; i < customerOrder.availableDocuments.length; i++) {
      for (let j = 0; j < customerOrder.availableDocuments[i].documents.length; j++) {
        const doc = customerOrder.availableDocuments[i].documents[j];

        doc.selected = selectedDocs.includes(doc.documentType);
      }
    }

    this[data.bundleType] = {
      subject,
      ccEmailsCheckbox,
      title,
      message,
      signerName: customerSigner.name,
      signerEmail: customerSigner.email,
      ccEmails: [],
      customDocument: null,
    };
  }

  onChangeRequestProps(type, propName, propValue) {
    if (propName === 'ccEmailsCheckbox') {
      const customerOrder = this.findSibling(d => d instanceof CustomerOrderModel);

      if (propValue) {
        this[type].ccEmails.push(customerOrder.dealerContactEmail);
      } else {
        this[type].ccEmails.splice(this[type].ccEmails.indexOf(customerOrder.dealerContactEmail), 1);
      }
    }

    if (propName === 'creditCustomerSigningDate') {
      propValue = propValue ? dayjs(propValue).format('MM / DD / YYYY') : '';
    }

    this[type][propName] = propValue;
    this.modelChanged(this);
  }

  onChangePdfOption(name, value) {
    this.customizePdfOptionsMap[name] = value;

    this.modelChanged(this);
  }

  getQueryString() {
    const params = [];

    for (let i = 0; i < CUSTOMIZE_PDF_OPTION_LIST.length; i++) {
      const prop = CUSTOMIZE_PDF_OPTION_LIST[i];

      params.push(prop + '=' + encodeURIComponent(this.customizePdfOptionsMap[prop]));
    }

    return params.join('&');
  }

  onChangeSignerEmail(signatureId, signerId, val) {
    const customerOrder = this.findSibling(d => d instanceof CustomerOrderModel);
    let breakLoop = false;

    // getSignature
    for (let i = 0; i < customerOrder.signatureHistory.length && !breakLoop; i++) {
      if (customerOrder.signatureHistory[i].id === signatureId) {
        //getSigner
        for (let j = 0; j < customerOrder.signatureHistory[i].eSigners.length && !breakLoop; j++) {
          if (customerOrder.signatureHistory[i].eSigners[j].id === signerId) {
            customerOrder.signatureHistory[i].eSigners[j].email = val;
            breakLoop = true;
          }
        }
      }
    }

    this.modelChanged(this);
  }
}

class CustomizePdfOptionsMap extends ModelAbstract {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this._ignoreOnExport.push('masterOrder');

    this._showNrcOnQuoteSummary = this.masterOrder.dealerNetToggle === false;
    this._showNrcPrices = this.masterOrder.dealerNetToggle === false;
    this._showQuoteDetails = true;
    this._showCustomerDepositInvoice = this.masterOrder.dealerNetToggle === false;
    this._showEstimatedLeasePayment = false;
  }

  get _masterOrderLookup() {
    return this.findSibling(d => d instanceof CustomerOrderModel);
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    return this._getCachedValueOnExport('_masterOrderLookup');
  }

  get showNrcOnQuoteSummary() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showNrcOnQuoteSummary;
  }
  set showNrcOnQuoteSummary(value) {
    this._showNrcOnQuoteSummary = Boolean(value);

    // Force OFF "NRC Prices on Quote Details" toggle when "NRC on Quote Summary" is set to OFF
    if (!this._showNrcOnQuoteSummary) {
      this._showNrcPrices = false;
    }
  }

  get showNrcPrices() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showNrcPrices;
  }
  set showNrcPrices(value) {
    this._showNrcPrices = Boolean(value);
  }

  get showQuoteDetails() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showQuoteDetails;
  }
  set showQuoteDetails(value) {
    this._showQuoteDetails = Boolean(value);
  }

  get showCustomerDepositInvoice() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showCustomerDepositInvoice;
  }
  set showCustomerDepositInvoice(value) {
    this._showCustomerDepositInvoice = Boolean(value);
  }

  get showEstimatedLeasePayment() {
    if (
      !this.showNrcOnQuoteSummary ||
      this.masterOrder.isFinanced ||
      this.masterOrder.leaseOption === LEASE_OPTIONS.NONE ||
      this.masterOrder.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0
    ) {
      return false;
    }

    return this._showEstimatedLeasePayment;
  }

  set showEstimatedLeasePayment(value) {
    this._showEstimatedLeasePayment = Boolean(value);
  }
}

class CustomizePdfOptionsDisabledMap extends ModelAbstract {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this._ignoreOnExport.push('eSigModel', 'masterOrder');

    this._showNrcOnQuoteSummary = false;
    this._showNrcPrices = false;
    this._showQuoteDetails = false;
    this._showCustomerDepositInvoice = false;
    this._showEstimatedLeasePayment = false;
  }

  /**
   * @returns {ElectronicSignatureModel}
   */
  get eSigModel() {
    return this._sibling;
  }

  get _masterOrderLookup() {
    return this.findSibling(d => d instanceof CustomerOrderModel);
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    return this._getCachedValueOnExport('_masterOrderLookup');
  }

  get showNrcOnQuoteSummary() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    if (!this.masterOrder.dealerNetToggle && AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) {
      return true;
    }

    return this._showNrcOnQuoteSummary;
  }
  set showNrcOnQuoteSummary(value) {
    this._showNrcOnQuoteSummary = Boolean(value);
  }

  get showNrcPrices() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    if (
      !this.eSigModel.customizePdfOptionsMap.showNrcOnQuoteSummary &&
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER
    ) {
      return true;
    }

    return this._showNrcPrices;
  }
  set showNrcPrices(value) {
    this._showNrcPrices = Boolean(value);
  }

  get showQuoteDetails() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showQuoteDetails;
  }
  set showQuoteDetails(value) {
    this._showQuoteDetails = Boolean(value);
  }

  get showCustomerDepositInvoice() {
    if (this.masterOrder.isFinanced) {
      return true;
    }

    return this._showCustomerDepositInvoice;
  }
  set showCustomerDepositInvoice(value) {
    this._showCustomerDepositInvoice = Boolean(value);
  }

  get showEstimatedLeasePayment() {
    if (
      !this.eSigModel.customizePdfOptionsMap.showNrcOnQuoteSummary ||
      this.masterOrder.isFinanced ||
      this.masterOrder.leaseOption === LEASE_OPTIONS.NONE ||
      this.masterOrder.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0
    ) {
      return true;
    }

    return this._showEstimatedLeasePayment;
  }

  set showEstimatedLeasePayment(value) {
    this._showEstimatedLeasePayment = Boolean(value);
  }

  unsetCreditCustomerSigningDate() {
    this[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT].creditCustomerSigningDate = '';
  }
}

export default ElectronicSignatureModel;
