import React from 'react';
import Summary from '../Summary';
import LeftBarButtons from './nested/LeftBarButtons';
import RightBarButtons from './nested/RightBarButtons';
import './css/toolbar.css';

const Toolbar = ({
  amendmentButton,
  approvalButton,
  cancelAmendmentButton,
  finalizeButton,
  finalizedButton,
  preliminaryButton,
  printFinalButton,
  saveButton,
  sendForSignatureButton,
  signaturesButton,
  slexButton,

  packageOne,
  packageTwo,
  packageThree,
  packageFour,

  onAmendBtnClick,
  onApprovalBtnClick,
  onCancelAmendmentBtnClick,
  onFinalizeBtnClick,
  onFinalizedBtnClick,
  preliminaryBtnEndpoint,
  printFinalPdfEndpoint,
  onSaveBtnClick,
  onSendForSignatureBtnClick,
  onSignaturesBtnClick,
  onSlexToolbarBtnClick,

  onDetailsClick,
  onSelectPackage,
  disableDetailsClick,

  isSelectedPackageOverride,
}) => {
  return (
    <div id="app-toolbar" className="toolbar-wrapper">
      <div className="toolbar max-width-container">
        <LeftBarButtons
          preliminaryBtnEndpoint={preliminaryBtnEndpoint}
          printFinalPdfEndpoint={printFinalPdfEndpoint}
          onSaveBtnClick={onSaveBtnClick}
          preliminaryButton={preliminaryButton}
          printFinalButton={printFinalButton}
          saveButton={saveButton}
        />
        <Summary
          onDetailsClick={onDetailsClick}
          onSelectPackage={onSelectPackage}
          packageOne={packageOne}
          packageTwo={packageTwo}
          packageThree={packageThree}
          packageFour={packageFour}
          isSelectedPackageOverride={isSelectedPackageOverride}
          disableDetailsClick={disableDetailsClick}
        />
        <RightBarButtons
          onAmendBtnClick={onAmendBtnClick}
          onApprovalBtnClick={onApprovalBtnClick}
          onCancelAmendmentBtnClick={onCancelAmendmentBtnClick}
          onFinalizeBtnClick={onFinalizeBtnClick}
          onFinalizedBtnClick={onFinalizedBtnClick}
          onSendForSignatureBtnClick={onSendForSignatureBtnClick}
          onSignaturesBtnClick={onSignaturesBtnClick}
          onSlexToolbarBtnClick={onSlexToolbarBtnClick}
          amendmentButton={amendmentButton}
          approvalButton={approvalButton}
          cancelAmendmentButton={cancelAmendmentButton}
          finalizeButton={finalizeButton}
          finalizedButton={finalizedButton}
          sendForSignatureButton={sendForSignatureButton}
          signaturesButton={signaturesButton}
          slexButton={slexButton}
        />
      </div>
    </div>
  );
};

export default Toolbar;
