import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AmountControl } from '../../Controls';

const TEXT_FIELD_PROPS = {
  type: 'Number',
  inputStyle: {
    textAlign: 'right',
    paddingRight: '10px',
    boxSizing: 'border-box',
  },
};

export default class SpiffAmount extends Component {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    errorText: PropTypes.string,
    isConfirmed: PropTypes.bool,
    spiffAmount: PropTypes.number,
    onChange: PropTypes.func,
    canBypassConfirmation: PropTypes.bool,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
    spiffWarningPooled: false,
    errorText: '',
    isConfirmed: false,
    madTotal: 0,
    spiffAmount: 0,
  };

  render() {
    const { lockedState, disabledState, errorText, isConfirmed, spiffAmount, onChange, canBypassConfirmation } =
      this.props;

    return (
      <AmountControl
        parentClassName={'spiff-amount'}
        onChange={onChange}
        errorText={errorText}
        hideErrorText={false}
        lockedState={lockedState}
        disabledState={(!canBypassConfirmation && isConfirmed) || disabledState}
        value={spiffAmount}
        id="spiffAmount"
        labelWidth={50}
        label={'msg_partner_target_spiff'}
        textFieldProps={TEXT_FIELD_PROPS}
        proposalInputStyle={{ marginTop: 15 }}
      />
    );
  }
}
