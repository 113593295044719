import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bindMultiple from '../../../common/helpers/bindMultiple';
import '../css/multi-select-minimal.css';
import { getTranslateString } from '../../../common/helpers/getTranslateString';
import { CheckboxControl } from '../index';
import translator from '../../../common/translate';

const OptionItem = ({ text = '', value = 0, onClick, selected, error }) => {
  const id = 'option-item-' + value.toLowerCase();
  const optionClassName = classNames({
    'select-option': true,
    'selected-option': selected,
    error: error > 0,
  });

  const onClickHandler = React.useCallback(() => onClick(value), [value, onClick]);

  return (
    <div tabIndex="0" id={id} title={text} className={optionClassName} data-value={value} onClick={onClickHandler}>
      <CheckboxControl
        id={id + 'checkbox'}
        className="select-option-checkbox small"
        parentClassName="small"
        value={selected}
        onChange={onClickHandler}
      />
      <span>{text}</span>
    </div>
  );
};

OptionItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool,
  error: PropTypes.number,
};

class MultiSelectMinimalControl extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onClearSelected: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    onBlur: PropTypes.func,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    clearInvalidOnBlur: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    clearInvalidOnBlur: true,
    isReadOnly: false,
  };

  buttonElem = null;
  dropDownElem = null;

  constructor(props) {
    super(props);

    this.state = {
      optionList: [],
    };

    this._foundEl = null;

    bindMultiple(
      this,
      this.__getSelectedOptionsField,
      this.__getSelectedOptionsValue,
      this.handleOnFocus,
      this.handleOnChange,
      this.handleSelectOption,
      this.handleInputKeyUp,
      this.handleOptionsKeyDown,
      this.openDropDownElem,
      this.closeDropDownElem,
      this.handleOnFocusout,
      this.handleClearSelectedOptions
    );
  }

  __getSelectedOptionsField(value, field) {
    return this.props.options
      .filter(o => value.includes(o.value))
      .reduce((result, _value) => {
        result.push(_value[field]);

        return result;
      }, []);
  }

  __getSelectedOptionsValue(value) {
    return this.__getSelectedOptionsField(value, 'value');
  }

  static getDerivedStateFromProps(props) {
    if (props.options?.length) {
      const options = props.options.map(option => {
        if (option.text.substring(0, 4) === 'msg_') {
          option.text = props.intl.formatMessage({ id: option.text });
        }

        return option;
      });

      return {
        optionList: options,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.dropDownElem.addEventListener('focusout', this.handleOnFocusout);
    this.buttonElem.addEventListener('focusout', this.handleOnFocusout);
  }

  componentDidUpdate(prevProps, state, value) {
    if (prevProps.focus && !this.props.focus) {
      this.buttonElem.blur();
    }
  }

  componentWillUnmount() {
    this.dropDownElem.removeEventListener('focusout', this.handleOnFocusout);
    this.buttonElem.removeEventListener('focusout', this.handleOnFocusout);
  }

  closeDropDownElem() {
    this.dropDownElem.style.display = 'none';

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  handleOnFocusout(e) {
    if (e.relatedTarget === null || e.relatedTarget.parentElement.className !== this.dropDownElem.className) {
      this.closeDropDownElem();
    }
  }

  openDropDownElem() {
    this.dropDownElem.style.display = 'block';
  }

  handleOnFocus() {
    this.openDropDownElem();
    let parentPosition = this.dropDownElem.parentNode.getBoundingClientRect();
    let listHeight = this.dropDownElem.offsetHeight;
    let toBottom = window.innerHeight - parentPosition.bottom;
    // 130px it's a height of the toolbar (fixed top)
    let toTop = parentPosition.top - 130;

    if (toBottom < listHeight && parentPosition.top > toBottom && toTop > listHeight) {
      this.dropDownElem.style.top = '';
      this.dropDownElem.style.bottom = `${parentPosition.height}px`;
    } else {
      this.dropDownElem.style.top = '100%';
      this.dropDownElem.style.bottom = '';
    }
  }

  handleOnChange(value) {
    !this.props.lockedState && !this.props.disabledState && this.props.onChange(value);
  }

  handleSelectOption(event) {
    const selectedTextValue = event.target.textContent;
    const selectedOption = this.props.options.find(o => {
      return o.text.toLowerCase().trim() === selectedTextValue.toLowerCase().trim();
    });

    this.handleOnChange(selectedOption.value);
  }

  handleClearSelectedOptions(ev) {
    this.props.onClearSelected();
  }

  handleOptionsKeyDown(event) {
    const key = event.key;
    const element = event.target;

    switch (key) {
      case 'ArrowDown':
        element.nextElementSibling ? element.nextElementSibling.focus() : this.dropDownElem.firstChild.focus();
        event.preventDefault();
        break;
      case 'ArrowUp':
        element.previousElementSibling ? element.previousElementSibling.focus() : this.buttonElem.focus();
        event.preventDefault();
        break;
      case 'Enter':
        this.handleSelectOption(event);
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleInputKeyUp(event) {
    const key = event.key;

    switch (key) {
      case 'ArrowDown':
        if (this.dropDownElem.firstChild) {
          this.dropDownElem.firstChild.focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (this.dropDownElem.lastChild) {
          this.dropDownElem.lastChild.focus();
        }
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      case 'Enter':
        if (this.state.optionList.length === 1) {
          let option = this.state.optionList[0];
          if (option.text.toLowerCase().trim() === event.target.value.toLowerCase().trim()) {
            this.buttonElem.value = option.text;
            this.buttonElem.blur();
          }
        }

        if (this._foundEl) {
          this.handleSelectOption();
        }

        event.preventDefault();
        break;
      default:
        break;
    }
  }

  __getTextByValue(optionValue) {
    return this.state.optionList.find(opt => opt.value === optionValue).text;
  }

  render() {
    const { id, className, values, lockedState, disabledState, intl, ariaLabel, label } = this.props;

    const ariaLabelString = getTranslateString(ariaLabel, intl);
    const buttonLabel = getTranslateString(label, intl);
    const buttonClassName = values.length === 0 ? 'tertiary' : '';
    const wrapperClassName = classNames(className, 'multi-select-minimal-control');

    return (
      <div className={wrapperClassName}>
        <button
          className={buttonClassName}
          id={id}
          ref={elem => (this.buttonElem = elem)}
          disabled={lockedState || disabledState}
          onChange={this.fieldOnChange}
          onFocus={this.handleOnFocus}
          onKeyUp={this.handleInputKeyUp}
          aria-label={ariaLabelString}
        >
          {buttonLabel}
          {values.length > 0 && `: ${this.__getTextByValue(values[0])}`}
          {values.length > 1 && <span className="additional-selected-options">+{values.length - 1}</span>}

          <span className="icon-expand-more field-icon" />
        </button>

        <div
          className="multi-select-minimal-option-list"
          onFocus={this.openDropDownElem}
          onKeyDown={this.handleOptionsKeyDown}
          ref={el => (this.dropDownElem = el)}
        >
          <button className="text-btn clear-selected-options no-border" onClick={this.handleClearSelectedOptions}>
            {translator.getMessage('msg_clear_selected_items')}
          </button>
          {this.state.optionList.map(option => (
            <OptionItem
              {...option}
              key={id + '-option-' + option.value}
              selected={values.includes(option.value)}
              onClick={this.handleOnChange}
              handleFindByTextInput={this.handleFindByTextInput}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(MultiSelectMinimalControl);
