import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import { ORDER_TYPES } from '../../common/enums';
import Loader from '../Loader';
import Dialog from '../Dialog';
import Table from './nested/Table';
import classNames from 'classnames';

const TITLE_MAP = {
  [ORDER_TYPES.ADD_ON]: 'msg_add_on_checkpoints',
  [ORDER_TYPES.NEW_CUSTOMER]: 'msg_master_order_checkpoints',
  [ORDER_TYPES.NEW_LOCATIONS]: 'msg_master_order_checkpoints',
  [ORDER_TYPES.REWRITE]: 'msg_master_order_checkpoints',
  locationTitle: 'msg_location_checkpoints',
};

class Checkpoints extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    isVisible: true,
  };

  render() {
    const { data, onClose, title, isVisible, isDataLoading } = this.props;

    const dialogProps = {
      title: (
        <div className="checkpoint-title-wrapper">
          <header className="checkpoint-title-header text-ellipsis">{translator.getMessage(TITLE_MAP[title])}</header>
        </div>
      ),
      actions: [
        <button key="checkpoint-button" className="checkpoint-button text-btn" onClick={onClose}>
          {translator.getMessage('msg_close')}
        </button>,
      ],
      onRequestClose: onClose,
      isOpen: isVisible,
      modalClassName: classNames({
        'checkpoints-modal': true,
        'is-loading': isDataLoading,
      }),
    };

    return <Dialog {...dialogProps}>{isDataLoading ? <Loader isVisible /> : <Table data={data} />}</Dialog>;
  }
}

export default Checkpoints;
