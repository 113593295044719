import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import config from '../../config';
import { TextControl } from '../Controls';
import './login.css';
import Modal from 'react-modal';
import { EMAIL_REGEX } from '../../common/enums';

export default class Login extends Component {
  static propTypes = {
    doLogin: PropTypes.func.isRequired,
    email: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      email: this.props.email || '',
      showError: false,
      errorInvalidPassword: false,
      errorMessage: '',
      passwordValidation: '',
      emailValidation: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnEmailChange = this.handleOnEmailChange.bind(this);
    this.handleOnPasswordChange = this.handleOnPasswordChange.bind(this);
  }

  validateCredentials(prop, value) {
    switch (prop) {
      case 'email':
        if (!value.match(EMAIL_REGEX)) {
          return 'msg_enter_correct_email';
        }

        return '';
      case 'password':
        if (value.length < 2) {
          return 'msg_password_is_to_short';
        }

        return '';
      default:
        return '';
    }
  }

  handleSubmit(ev) {
    ev.preventDefault();

    if (this.state.passwordValidation || this.state.emailValidation || !this.state.password || !this.state.email) {
      return;
    }

    // Hide error if any shown before sending login request
    this.setState({
      showError: false,
    });

    this.props.doLogin(this.state.password, this.state.email).catch(error => {
      const state = {
        showError: true,
        errorInvalidPassword: false,
      };

      try {
        const statusCode = error.response.status;
        const statusMessage = error.response.data.message;

        if (statusCode === 401) {
          state.errorInvalidPassword = true;
          state.errorMessage = '';
        } else if (statusMessage) {
          state.errorMessage = statusMessage;
        }
      } catch {
        state.errorMessage = 'Unknown error occurred. Please try again.';
      }

      this.setState(state);

      return error;
    });
  }

  handleSubmitToPolaris() {
    window.open(config.loginPage, '_self');
  }

  handleOnChange(value, name) {
    this.setState({
      [name]: value,
      [name + 'Validation']: this.validateCredentials(name, value),
    });
  }

  handleOnEmailChange(value) {
    this.handleOnChange(value, 'email');
  }

  handleOnPasswordChange(value) {
    this.handleOnChange(value, 'password');
  }

  render() {
    const loginIsDisabled = this.state.emailValidation || this.state.passwordValidation;

    return (
      <Modal
        isOpen={true}
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal login-modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
      >
        <div className="default-modal-label">
          <p className="light-bold">{translator.getMessage('msg_sing_in_to_rocket_quote')}</p>
          <form action={config.api.url + '/integration/sso/login'} method="post" onSubmit={this.handleSubmit}>
            <TextControl
              id="login-user-name"
              required={true}
              onChange={this.handleOnEmailChange}
              value={this.state.email}
              label="msg_user_name"
              errorText={this.state.emailValidation}
            />
            <TextControl
              inputType="password"
              id="login-password"
              required={true}
              onChange={this.handleOnPasswordChange}
              value={this.state.password}
              label="msg_password"
              errorText={this.state.passwordValidation}
            />

            {/* Form submit by enter key does not work without "submit" button inside form tag */}
            {/* But it does work with a hidden button */}
            <button type="submit" hidden />
          </form>
          {this.state.showError && (
            <small className="login-error">
              {this.state.errorInvalidPassword
                ? translator.getMessage('msg_your_user_name_or_password_is_invalid')
                : this.state.errorMessage}
            </small>
          )}
        </div>
        <div className="default-confirm-modal-actions">
          <button
            key="login-to-polaris-modal"
            id="login-to-polaris-modal"
            className="text-btn"
            onClick={this.handleSubmitToPolaris}
          >
            {translator.getMessage('msg_polaris')}
          </button>
          <button key="login-modal" id="login-modal" onClick={this.handleSubmit} disabled={loginIsDisabled}>
            {translator.getMessage('msg_login')}
          </button>
        </div>
      </Modal>
    );
  }
}
