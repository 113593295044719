import PropTypes from 'prop-types';

const LocationActionBtn = () => {
  return this.props.children;
};

LocationActionBtn.proptypes = {
  children: PropTypes.node.isRequired,
};

export default LocationActionBtn;
