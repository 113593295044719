import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import {
  ESIG_DOCS_LABELS,
  QUOTE_STATUS,
  SIGNATURE_STATUS,
  USER_ROLES,
  ESIG_ENVELOPE_TYPES,
  ESIG_ENVELOPE_TYPE_LABELS,
} from '../../../common/enums';
import { AuthContext } from '../../../Context';
import { UpperCaseEachWord } from '../../../common/helpers/format';
import translator from '../../../common/translate';
import bindMultiple from '../../../common/helpers/bindMultiple';
import { TextControl } from '../../Controls';
import BadgeButton from '../../BadgeButton';
import HiddenFormHelper from '../../HiddenFormHelper';
import PdfHiddenForm from '../../PdfHiddenForm';
import dayjs from 'dayjs';

class HistoryRow extends Component {
  static propTypes = {
    id: PropTypes.number,
    bundleType: PropTypes.string,
    creationDate: PropTypes.number,
    lastModified: PropTypes.number,
    status: PropTypes.string,
    crmApprovalTicket: PropTypes.string,
    eSignatureDocuments: PropTypes.arrayOf(PropTypes.object),
    bounced: PropTypes.bool,
    bouncedEmailAddress: PropTypes.string,

    customerSigner: PropTypes.object,
    counterSigner: PropTypes.object,

    onClickCancel: PropTypes.func,
    getPreviewUrlAndValues: PropTypes.func,
    onClickDetails: PropTypes.func,
    onClickReSubmit: PropTypes.func,
    onClickVoid: PropTypes.func,
    getSignatureDownloadEndpoint: PropTypes.func,

    cancelDisabled: PropTypes.bool,
    reSubmitAllowed: PropTypes.bool,
    allowToVoid: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    quoteStatus: PropTypes.string,
    isExistingAls: PropTypes.bool,

    isEnvelopeAvailable: PropTypes.bool,
    isOdd: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      crmApprovalTicketNumber: '',
    };

    bindMultiple(
      this,
      this.handleClickCancel,
      this.handleClickDetails,
      this.handleClickReSubmit,
      this.handleClickVoid,
      this.setCrmApprovalTicketNumber
    );
  }

  handleClickCancel() {
    if (typeof this.props.onClickCancel === 'function') {
      this.props.onClickCancel(this.props.id);
    }
  }

  handleClickDetails() {
    if (typeof this.props.onClickDetails === 'function') {
      this.props.onClickDetails(this.props.id);
    }
  }

  handleClickReSubmit() {
    if (typeof this.props.onClickReSubmit === 'function') {
      this.props.onClickReSubmit(this.props, this.props.bundleType);
    }
  }

  handleClickVoid() {
    if (typeof this.props.onClickVoid === 'function') {
      this.props.onClickVoid(this.props.id, this.state.crmApprovalTicketNumber);
    }
  }

  renderSigner(signer, bounced, bouncedEmailAddress) {
    const key = 'sig-' + this.props.id + '-signer-' + signer.id;
    const status = bounced && bouncedEmailAddress === signer.email ? SIGNATURE_STATUS.BOUNCED : signer.status;

    return (
      <span id={key} key={key} data-signer-id={signer.id} className="signer">
        <span className="name" title={signer.name}>
          {signer.name}
        </span>
        <span className="email">
          <a href={'mailto:' + signer.email} title={signer.email}>
            {signer.email}
          </a>
        </span>
        <span className="status" title={status}>
          {status}
        </span>
      </span>
    );
  }

  getDocumentLabel(type) {
    return ESIG_DOCS_LABELS[type] ? translator.getMessage(ESIG_DOCS_LABELS[type]) : UpperCaseEachWord(type, '_');
  }

  __getBundleLabel(bundleType) {
    if (!bundleType) {
      return '';
    }

    const agreementAndOrderLabel = this.props.isExistingAls ? 'ORDER' : 'QUOTE';
    const labelType = bundleType === ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER ? agreementAndOrderLabel : bundleType;

    return this.props.intl.formatMessage({ id: ESIG_ENVELOPE_TYPE_LABELS[labelType] });
  }

  setCrmApprovalTicketNumber(crmApprovalTicketNumber) {
    this.setState({ crmApprovalTicketNumber });
  }

  render() {
    const {
      id,
      creationDate,
      lastModified,
      status,
      crmApprovalTicket,
      bounced,
      bouncedEmailAddress,
      bundleType,
      eSignatureDocuments,
      customerSigner,
      counterSigner,
      reSubmitAllowed,
      allowToVoid,
      isSalesOps,
      quoteStatus,
      cancelDisabled,
      isOdd,
      getPreviewUrlAndValues,
      allSigned,
    } = this.props;

    const voidDisabled = this.state.crmApprovalTicketNumber === '';
    const showVoidButton = Boolean(
      isSalesOps &&
        SIGNATURE_STATUS.COMPLETED === status &&
        quoteStatus !== QUOTE_STATUS.AMENDING &&
        ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT !== bundleType &&
        allowToVoid
    );
    const btnGroupClassNames = classNames({
      'btn-collection': true,
      small: true,
    });
    const statusTitle = status === SIGNATURE_STATUS.VOIDED ? status + ' - ' + crmApprovalTicket : status;
    const statusClassName = classNames({
      status: true,
      'status-voided': status === SIGNATURE_STATUS.VOIDED,
    });

    const contents = eSignatureDocuments.map(d => (
      <span id={'sig-' + id + '-document-' + d.id} key={'sig-' + id + '-document-' + d.id} data-document-id={d.id}>
        {this.getDocumentLabel(d.documentType)}
      </span>
    ));
    const showCounterSigner = AuthContext.model.hasInternalPermissions;

    const typeClassNames = ['type'];
    const customerSignerClassNames = ['customer-signer', 'signers', 'auto-width-ellipsis'];

    if (!showCounterSigner) {
      typeClassNames.push('nowrap');
      customerSignerClassNames.push('auto-width');
    }

    const downloadDisabled =
      status !== SIGNATURE_STATUS.COMPLETED && AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER;

    const oddEvenClassName = isOdd ? 'odd' : 'even';
    const previewUrlAndValues = getPreviewUrlAndValues(this.props);

    const displayCancelButton =
      allSigned && status === SIGNATURE_STATUS.SENT
        ? false
        : [SIGNATURE_STATUS.SENT, SIGNATURE_STATUS.ON_HOLD].includes(status);

    return (
      <React.Fragment>
        <tr className={'history-data-row ' + oddEvenClassName} id={'signature-history-row-' + id} data-sig-id={id}>
          <td className={typeClassNames.join(' ')}>{this.__getBundleLabel(bundleType)}</td>
          <td className="contents">{contents}</td>
          <td className={customerSignerClassNames.join(' ')}>
            {customerSigner === null ? 'N/A' : this.renderSigner(customerSigner, bounced, bouncedEmailAddress)}
          </td>
          {showCounterSigner && (
            <td className="counter-signer signers">
              {counterSigner === null ? 'N/A' : this.renderSigner(counterSigner, bounced, bouncedEmailAddress)}
            </td>
          )}
          <td className="sent" data-sent-timestamp={creationDate}>
            {dayjs(creationDate).format('MM/DD/YYYY h:mm:ss A')}
          </td>
          <td className="updated" data-updated-timestamp={lastModified}>
            {dayjs(lastModified).format('MM/DD/YYYY h:mm:ss A')}
          </td>
          <td className={statusClassName}>{statusTitle}</td>
        </tr>
        <tr
          className={'history-actions-row ' + oddEvenClassName}
          id={'signature-history-actions-' + id}
          data-sig-id={id}
        >
          <td className="actions" colSpan={showCounterSigner ? 7 : 6}>
            <span className={btnGroupClassNames}>
              {showVoidButton && (
                <div className="void-input-btn">
                  <TextControl
                    id={'crm-approval-ticket-' + id}
                    label="msg_crm_approval_ticket"
                    value={this.state.crmApprovalTicketNumber}
                    onChange={this.setCrmApprovalTicketNumber}
                  />
                  <button
                    disabled={voidDisabled}
                    key={'signature-void-' + id}
                    id={'signature-void-' + id}
                    className="btn-signature-void text-btn small"
                    onClick={this.handleClickVoid}
                  >
                    {translator.getMessage('msg_void')}
                  </button>
                </div>
              )}

              {displayCancelButton && (
                <button
                  disabled={cancelDisabled}
                  key={'signature-cancel-' + this.props.id}
                  id={'signature-cancel-' + this.props.id}
                  className="btn-signature-cancel text-btn"
                  onClick={this.handleClickCancel}
                >
                  {translator.getMessage('msg_esig_history_dialog_row_buttons_cancel')}
                </button>
              )}

              {AuthContext.model.hasSalesOpsPermissions &&
                [
                  SIGNATURE_STATUS.SENT,
                  SIGNATURE_STATUS.ON_HOLD,
                  SIGNATURE_STATUS.DECLINED,
                  SIGNATURE_STATUS.COMPLETED,
                ].includes(status) && (
                  <React.Fragment>
                    <button
                      key={'signature-preview-' + this.props.id}
                      id={'signature-preview-' + this.props.id}
                      className="btn-signature-preview"
                      form={'signature-preview-' + this.props.id + '-form'}
                      type="submit"
                    >
                      {translator.getMessage('msg_esig_history_dialog_row_buttons_preview')}
                    </button>
                    <HiddenFormHelper
                      id={'signature-preview-' + this.props.id + '-form'}
                      key={'signature-preview-' + this.props.id + '-form'}
                      action={previewUrlAndValues.url}
                      values={previewUrlAndValues.values}
                    />
                  </React.Fragment>
                )}

              {AuthContext.model.hasInternalPermissions &&
                [ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER, ESIG_ENVELOPE_TYPES.AMENDMENT].includes(bundleType) &&
                SIGNATURE_STATUS.CANCELED !== status && [
                  <BadgeButton
                    key={'signature-download-' + this.props.id}
                    id={'signature-download-' + this.props.id}
                    className="btn-signature-download"
                    disabled={downloadDisabled}
                    label={translator.getMessage('msg_download')}
                    infoTooltip={downloadDisabled ? 'msg_available_after_all_signed' : ''}
                    form={'signature-download-' + this.props.id + '-form'}
                    hideInfoBadge
                  />,
                  <PdfHiddenForm
                    id={'signature-download-' + this.props.id + '-form'}
                    key={'signature-download-' + this.props.id + '-form'}
                    endpoint={this.props.getSignatureDownloadEndpoint(this.props.id)}
                  />,
                ]}

              {this.props.isEnvelopeAvailable &&
                reSubmitAllowed &&
                [SIGNATURE_STATUS.FAILED, SIGNATURE_STATUS.CANCELED, SIGNATURE_STATUS.DECLINED].includes(status) && (
                  <button
                    key={'signature-re-submit-' + this.props.id}
                    id={'signature-re-submit-' + this.props.id}
                    className="btn-signature-re-submit primary"
                    onClick={this.handleClickReSubmit}
                  >
                    {translator.getMessage('msg_re_submit')}
                  </button>
                )}

              <button
                key={'signature-details-' + this.props.id}
                id={'signature-details-' + this.props.id}
                className="btn-signature-details"
                onClick={this.handleClickDetails}
              >
                {translator.getMessage('msg_esig_history_dialog_row_buttons_details')}
              </button>
            </span>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default injectIntl(HistoryRow);
