import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DayButton from './DayButton';
import '../css/calendar.css';
import bindMultiple from '../../../../common/helpers/bindMultiple';

class Calendar extends PureComponent {
  static propTypes = {
    calendarInfo: PropTypes.object,
    onDayClick: PropTypes.func,
    doFocus: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.calendarRef = createRef();

    bindMultiple(this, this.handleOnKeyDown, this.handleOnFocus);
  }

  componentDidUpdate() {
    const calendarRef = this.calendarRef.current;

    if (calendarRef && this.props.doFocus) {
      const preselected = calendarRef.querySelector('button.day-btn-component.preselected-day:not([disabled])');
      const today = calendarRef.querySelector('button.day-btn-component.is-today:not([disabled])');
      const firstActive = calendarRef.querySelector('button.day-btn-component:not([disabled])');

      if (preselected !== null) {
        preselected.focus();
      } else if (today !== null) {
        today.focus();
      } else if (firstActive !== null) {
        firstActive.focus();
      }
    }
  }

  render() {
    const { calendarInfo, onDayClick } = this.props;
    const weekDaysClassNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const selectedWeekDay = calendarInfo?.value.day();
    const isSelectedMonth =
      calendarInfo &&
      calendarInfo.visibleDate.month() === calendarInfo.value.month() &&
      calendarInfo.visibleDate.year() === calendarInfo.value.year();
    const days = [];

    if (calendarInfo?.daysList) {
      for (let i = 0; i < calendarInfo.daysList.length; i++) {
        const day = calendarInfo.daysList[i];

        let props = {
          ...day,
          className: weekDaysClassNames[day.weekDay],
        };

        if (!day.disabled) {
          props.onClick = onDayClick;
        }

        days.push(<DayButton key={`day-btn-${weekDaysClassNames[day.weekDay]}-${i}`} {...props} />);
      }
    }

    return (
      <div
        ref={this.calendarRef}
        className="calendar-component"
        onKeyDown={this.handleOnKeyDown}
        onFocus={this.handleOnFocus}
        tabIndex="0"
      >
        {weekDaysClassNames.map((d, i) => {
          let className = classNames({
            'week-days': true,
            selected: isSelectedMonth && selectedWeekDay === i,
          });

          return (
            <div key={d} className={className}>
              {d}
            </div>
          );
        })}
        {days}
      </div>
    );
  }

  handleOnKeyDown(e) {
    const calendarRef = this.calendarRef.current;
    const calendarChildrenCount = calendarRef.children.length;

    switch (e.key) {
      case 'ArrowLeft':
        if (e.target.previousSibling) {
          e.target.previousSibling.focus();
        }

        e.preventDefault();
        break;
      case 'ArrowRight':
        if (e.target.nextSibling) {
          e.target.nextSibling.focus();
        }

        e.preventDefault();
        break;
      case 'ArrowUp': {
        for (let i = 0; i < calendarChildrenCount; i++) {
          if (calendarRef.children[i] === e.target) {
            if (calendarRef.children[i - 7]) {
              calendarRef.children[i - 7].focus();
            }
          }
        }

        e.preventDefault();
        break;
      }
      case 'ArrowDown': {
        for (let i = 0; i < calendarChildrenCount; i++) {
          if (calendarRef.children[i] === e.target) {
            if (calendarRef.children[i + 7]) {
              calendarRef.children[i + 7].focus();
            }
          }
        }

        e.preventDefault();
        break;
      }
      default:
        break;
    }
  }

  handleOnFocus() {
    const calendarRef = this.calendarRef.current;
    const firstDay = calendarRef.querySelector('.day-btn-component');

    firstDay.focus();
  }
}

export default Calendar;
