import { CELL_TYPES } from '../../components/LineItems/enums';
import { ORDER_TYPES, QUOTE_STATUS } from '../../common/enums';
import { AuthContext, PriceBookContext } from '../../Context';
import config from '../../config';
import TypeConverter from '../../common/helpers/TypeConverter';
import fixFloat from '../../common/helpers/fixFloat';
import bindMultiple from '../../common/helpers/bindMultiple';
import { RESULT_TYPES } from '../CalcResultsModel';
import {
  ModelAbstract,
  QuoteModel,
  LineItemsCollectionModel,
  QuotesCollectionModel,
  LocationQuoteModel,
  CustomerOrderModel,
  LocationLineItemModel,
} from '../';
import apiData from '../../Storage/apiData';

class LineItemModel extends ModelAbstract {
  constructor(higherOrderClassInstance) {
    super(higherOrderClassInstance);

    this._ignoreOnExport.push('linkedLineItems', 'subItems', 'masterOrder');

    this._active = true;
    this._hidden = false;
    this._quantity = 0;
    this._dealerNet = 0;
    this._removable = true;
    this._numberValueType = 'positive';
    this._amountValueType = 'positive';
    this._doFocus = false;
    this._quoteLineItemUuid = null;
    this._numPortsOverride = null;
    this._overrideValue = null;
    this._overrideQuantity = null;
    this._markupValue = 0;
    this._partnerProductName = '';
    this._cellTypes = {
      quantity: CELL_TYPES.number,
      overrideValue: CELL_TYPES.amount,
      overrideQuantity: CELL_TYPES.number,
      markupValue: CELL_TYPES.amount,
      numPortsOverride: CELL_TYPES.number,
      dealerNet: CELL_TYPES.label,
      partnerProductName: CELL_TYPES.label,
    };
    this._existing = false;
    this._recurring = null;
    this._applicableSolutionTypes = [];

    this.extensionFactor = 0;
    this.lineItemSubCategoryName = '';
    this.catalogFlags = [];
    this.cellTypes = { ...this._cellTypes };
    // Pricebook product ID
    this.productId = null;
    this.subLineItems = [];
    this.lineItemName = '';

    bindMultiple(
      this,
      this.getFlag,
      this.getFlagValue,
      this.hasFlag,
      this.hasProductTag,
      this.isTagApplicable,
      this.onChange,
      this.onLineItemMounted,
      this.onRemove
    );
  }

  get applicableSolutionTypes() {
    return this._applicableSolutionTypes;
  }

  set applicableSolutionTypes(value) {
    if (!Array.isArray(value)) {
      throw new Error('LineItemModel.applicableSolutionTypes value should be an Array');
    }

    if (value.length === 0) {
      throw new Error('LineItemModel.applicableSolutionTypes array can not be empty');
    }

    // Force convert all values in array to Number
    // applicableSolutionTypes are generated from flag values on BE side
    // and all flag values are strings initially
    this._applicableSolutionTypes = value.map(solutionType => Number(solutionType));
  }

  /**
   * @returns {CustomerOrderModel|null}
   */
  get masterOrder() {
    /** @type {QuoteModel} */
    const quoteModel = this.findSibling(i => i instanceof QuoteModel);

    if (quoteModel?.controller) {
      return quoteModel.controller.quotes.masterOrder;
    }

    return null;
  }

  setProps(props, skipProps = []) {
    skipProps.push('existing');

    super.setProps(props, skipProps);

    if (props.existing) {
      this.setExisting(props.existing);
    }

    return this;
  }

  onChange(prop, value) {
    this[prop] = value;
    this.modelChanged(this);
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {boolean}
   */
  get isPresentOnQuote() {
    return true;
  }

  /**
   * Line item trash can icon onClick handler
   * @returns {boolean}
   */
  onRemove() {
    if (!this.masterOrder) {
      return false;
    }

    const dialog = this.masterOrder.lineItemRemoveDialog;

    if (this.allocated) {
      dialog.lineItemUuid = this._uuid;
      dialog.isOpen = true;
      dialog.allocated = this.allocated;
      dialog.name = this.lineItemName;

      this.forceRender();
    } else {
      this.masterOrder.lineItems.remove(this._uuid);

      this.modelChanged(this);
    }
  }

  onLineItemMounted() {
    this._doFocus = false;
  }

  /**
   * Removes line items recursively
   * @param {String} lineItemUuid
   * @param {Boolean} performRecursiveRemove Do perform nested remove or not
   * @param {Boolean} isDirectRemoveCall Is item removed directly or recursively
   * @returns {Boolean}
   * @private
   */
  _remove(lineItemUuid, performRecursiveRemove, isDirectRemoveCall = false) {
    /** @type {LineItemsCollectionModel} */
    const collectionModel = this.findSibling(d => d instanceof LineItemsCollectionModel);

    if (!collectionModel) {
      throw new Error('Line item ' + lineItemUuid + ' remove failed. Unable to find lineItemsCollectionModel.');
    }

    /** @type {LineItemModel} */
    const lineItem = collectionModel.items.find(lineItem => lineItem.uuid === lineItemUuid);

    if (!lineItem) {
      return false;
    }

    const directBundleParents = lineItem.linkedLineItems;
    const isFixedOnUi = lineItem.hasFlag('isFixedOnUi');

    // Decrease quantity in case of fixed item or any other bundle parents are available
    // TODO: Do we still need this logic? Fixed and children items quantity is auto-calculated anyway.
    //       manualCountsAtLocation flagged items may still require this piece of code.
    if ((lineItem.active && isFixedOnUi) || (directBundleParents.length && !isDirectRemoveCall)) {
      // Recalculate quantity
      lineItem.quantity = directBundleParents.reduce((a, b) => a.quantity + b.quantity, 0);

      if (config.showConsoleLog) {
        console.log('LineItemModel.remove()', ' quantity changed    ', lineItem.productId, lineItem.lineItemName);
      }

      if (performRecursiveRemove) {
        this._removeSubItems(lineItem, performRecursiveRemove);
      }

      return true;
    }

    // Remove item permanently
    collectionModel.removeFromCollectionByUuid(lineItemUuid);

    if (config.showConsoleLog) {
      console.log('LineItemModel.remove()', ' removed permanently ', lineItem.productId, lineItem.lineItemName);
    }

    if (performRecursiveRemove) {
      this._removeSubItems(lineItem, performRecursiveRemove);
    }

    return true;
  }

  _removeSubItems(lineItem, performRecursiveRemove) {
    const bundleChildren = lineItem.subItems;

    for (let i = 0; i < bundleChildren.length; i++) {
      /** @type {LineItemModel} */
      const bundleChild = bundleChildren[i];

      this._remove(bundleChild.uuid, performRecursiveRemove);
    }
  }

  /**
   * Removes line items recursively
   * @param {Boolean} performRecursiveRemove
   * @returns {boolean}
   */
  remove(performRecursiveRemove = true) {
    return this._remove(this._uuid, performRecursiveRemove, true);
  }

  get _linkedLineItemsLookup() {
    /** @type {QuoteModel} */
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!(quote instanceof QuoteModel) || quote.lineItems === null) {
      return [];
    }

    /** @type {LineItemModel[]} */
    const parents = quote.lineItems.items.filter(lineItem =>
      lineItem.subLineItems.some(d => d.subLineItemId === this.lineItemId)
    );

    // Filter out "existing" parents if child is NRC
    return parents.filter(parent => !parent.existing || (parent.existing && this.recurring));
  }

  /**
   * Returns an array of parent items from a bundle
   * @returns {LineItemModel[]}
   */
  get linkedLineItems() {
    return this._getCachedValueOnExport('_linkedLineItemsLookup');
  }

  get subItems() {
    const quote = this.findSibling(d => d instanceof QuoteModel);
    const subIds = (this.subLineItems || []).map(d => d.subLineItemId);

    return quote ? quote.lineItems.items.filter(d => subIds.indexOf(d.lineItemId) !== -1) : [];
  }

  get _quantityGetter() {
    this.cellTypes.quantity = CELL_TYPES.number;

    let flag = false;
    const quote = this.findSibling(parent => parent instanceof QuoteModel);

    if (quote) {
      const siblings = this.linkedLineItems;

      if (siblings.length) {
        this._quantity = 0;

        // KM-5468: Calculate child quantity value based on specific parent's property
        // "quantity" is used if no flag set
        const bundleChildCountFromParentProperty = this.getFlag('bundleChildCountFromParentProperty');
        const parentPropertyName = bundleChildCountFromParentProperty
          ? bundleChildCountFromParentProperty.valueAsString
          : 'quantity';

        for (let i = 0; i < siblings.length; i++) {
          const parentValue = siblings[i][parentPropertyName];

          // Skip non numeric values
          if (typeof parentValue !== 'number') {
            continue;
          }

          this._quantity += parentValue;
        }

        this.cellTypes.quantity = CELL_TYPES.label;
      }

      // eslint-disable-next-line
      if ((flag = this.getFlag('fixedCountByPropertyName'))) {
        this._quantity = parseInt(quote[flag.valueAsString], 10) || 0;
        this.cellTypes.quantity = CELL_TYPES.label;
      }
    }

    // eslint-disable-next-line
    if ((flag = this.getFlag('countValueFromResults'))) {
      this._quantity = quote.calcResults.getValue(flag.valueAsString, RESULT_TYPES.integer);
      this.cellTypes.quantity = CELL_TYPES.label;
    }

    // eslint-disable-next-line
    if ((flag = this.getFlag('fixedCount'))) {
      this._quantity = parseInt(flag.valueAsString, 10) || 0;
      this.cellTypes.quantity = CELL_TYPES.label;
    }

    return this._quantity;
  }

  get dealerNetPrice() {
    return this.price;
  }

  get quantity() {
    return this._getCachedValueOnExport('_quantityGetter');
  }

  set quantity(value) {
    this._quantity = value;
  }

  get quantityWithOverride() {
    return this._overrideQuantity !== null ? this._overrideQuantity : this.quantity;
  }

  get active() {
    return this._active;
  }

  set active(value) {
    this._active = Boolean(value);
  }

  get _dealerNetLookup() {
    const defaultValue = this._dealerNet;
    /** @type {QuoteModel} */
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!quote) {
      return defaultValue;
    }

    const dealerNetValueFromResults = this.getFlag('dealerNetValueFromResults');

    if (dealerNetValueFromResults) {
      const { packageIdSelected } = quote;
      const dealerNetValueResultsPackaged = this.getFlag('dealerNetValueResultsPackaged');
      const prop = dealerNetValueFromResults.valueAsString + (dealerNetValueResultsPackaged ? packageIdSelected : '');

      return quote.calcResults.getValue(prop, RESULT_TYPES.float) || 0;
    }

    // KM-13161: Get tier price from Master Order calculation results if available
    if (quote instanceof CustomerOrderModel || quote instanceof LocationQuoteModel) {
      /** @type {CustomerOrderModel} */
      const masterOrder = quote instanceof LocationQuoteModel ? quote.masterOrder : quote;
      const quoteLineItemUuid =
        this instanceof LocationLineItemModel ? this.parentQuoteLineItemUuid : this.quoteLineItemUuid;
      const tieredPriceString = masterOrder.calcResults.lineItemsWithTieredPrices[quoteLineItemUuid];

      if (typeof tieredPriceString === 'string') {
        return parseFloat(Number(tieredPriceString).toFixed(4));
      }
    }

    return defaultValue;
  }

  get dealerNet() {
    // KM-10906: Price shall always be 0 for existing line items
    if (this.existing) {
      return 0;
    }

    return this._getCachedValueOnExport('_dealerNetLookup');
  }

  set dealerNet(value) {
    this._dealerNet = value === '' ? null : value;
  }

  get price() {
    return this.overrideValue !== null ? this.overrideValue : this.dealerNet;
  }

  get quotePrice() {
    return fixFloat(this.price + this.markupValue);
  }

  get totalMarkup() {
    return fixFloat(this.quantityWithOverride * this.markupValue);
  }

  get total() {
    return fixFloat(this.quantityWithOverride * this.quotePrice);
  }

  get _hiddenLookup() {
    if (this.active === false) {
      return false;
    }

    const hiddenItem = (this.getFlagValue('hiddenItem') || 'false').toLowerCase();

    if (hiddenItem === 'true') {
      // hiddenItem flag has priority on hideIfZeroCount and specificForPackageX flags
      // So just return true value
      return true;
    }

    const hideIfZeroCount = this.getFlagValue('hideIfZeroCount');

    if (hideIfZeroCount && this.quantity === 0 && this._overrideQuantity === null) {
      const manualCountsAtLocation = (this.getFlagValue('manualCountsAtLocation') || 'false').toLowerCase() === 'true';

      if (this instanceof LocationLineItemModel && manualCountsAtLocation) {
        /** @type {LineItemModel} */
        const masterOrderSibling = this.parent;

        if (masterOrderSibling.quantity === 0 && masterOrderSibling.overrideQuantity === null) {
          return true;
        }
      } else {
        return true;
      }
    }

    /** @type {QuoteModel} */
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (quote) {
      const packageId = quote.packageIdSelected;
      let hasAnyFlagForSpecificPackage = false;

      for (let i = 1; i <= 4; i++) {
        if (this.hasFlag('specificForPackage' + i)) {
          hasAnyFlagForSpecificPackage = true;
          break;
        }
      }

      if (hasAnyFlagForSpecificPackage && !this.hasFlag('specificForPackage' + packageId)) {
        return true;
      }
    }

    // None of the flags applied then return protected property
    return this._hidden;
  }

  get hidden() {
    return this._getCachedValueOnExport('_hiddenLookup');
  }

  set hidden(value) {
    this._hidden = Boolean(value);
  }

  get removable() {
    // KM-6361: Prevent item removal if some of finalized locations has this item allocated
    if (this.allocatedFinalized) {
      return false;
    }

    // KM-3797 inactive line items should be removable for non finalized quotes
    if (!this._active) {
      return true;
    }

    if (this.hasFlag('isFixedOnUi')) {
      return false;
    }

    if (this.isTagApplicable('readOnlyIfCannotAdd')) {
      return false;
    }

    // KM-3882: [DEPRECATED] If line item is a child of another line item it should not be removable by itself
    // KM-5054: Logic changed. Bundled sub line items can be removed by default
    if (this.hasFlag('deleteOnlyWithParent') && this.linkedLineItems.length) {
      // KM-5805: SalesOps should be able to remove any sub-items
      return AuthContext.model.hasSalesOpsPermissions;
    }

    return this._removable;
  }

  set removable(value) {
    this._removable = Boolean(value);
  }

  get doFocus() {
    return this._doFocus;
  }

  set doFocus(value) {
    this._doFocus = Boolean(value);
  }

  get numberValueType() {
    const quote = this.findSibling(d => d instanceof QuoteModel);
    return quote && quote.orderType === ORDER_TYPES.REDUCTION ? 'negative' : this._numberValueType;
  }

  set numberValueType(value) {
    this._numberValueType = value;
  }

  get amountValueType() {
    return this.hasFlag('negativePricing') ? 'negative' : this._amountValueType;
  }

  set amountValueType(value) {
    this._amountValueType = value;
  }

  getFlag(flag) {
    return this.catalogFlags.length ? this.catalogFlags.find(d => d.flag.name === flag) : false;
  }

  /**
   * Returns string value
   * Returns null if valueAsString is not specified
   * Returns undefined if flag not found
   *
   * @param {string} flagName
   * @returns {string|null|undefined}
   */
  getFlagValue(flagName) {
    if (this.existing) {
      // Make existing line items always act like they have some certain flags
      if (['disableMarkup', 'disableOverride'].includes(flagName)) {
        return 'TRUE';
      }
    }

    const flag = this.getFlag(flagName);

    if (!flag) {
      return undefined;
    }

    return flag.valueAsString !== undefined ? flag.valueAsString : null;
  }

  getFlagsArray(flag) {
    return this.catalogFlags.filter(d => d.flag.name === flag);
  }

  getFlagValuesArray(flagName) {
    return this.getFlagsArray(flagName).map(({ valueAsString }) => (valueAsString !== undefined ? valueAsString : null));
  }

  hasFlag(flag) {
    return Boolean(this.getFlag(flag));
  }

  hasProductTag(productTag) {
    return this.catalogFlags.some(d => d.flag.name === 'productTag' && d.valueAsString === productTag);
  }

  isTagApplicable(productTag) {
    // If tag is missing. Consider it as not applicable
    if (!this.hasProductTag(productTag)) {
      return false;
    }

    if (productTag === 'readOnlyIfCannotAdd') {
      return this.allowedToAdd === false;
    }

    return false;
  }

  get quoteLineItemUuid() {
    if (this._quoteLineItemUuid === null) {
      this._quoteLineItemUuid = this.uuid;
    }

    return this._quoteLineItemUuid;
  }

  set quoteLineItemUuid(value) {
    this._quoteLineItemUuid = value;
  }

  // TODO: Remove numPorts getter if it will be added into DB
  // For now numPorts is equal to extensionFactor
  get numPorts() {
    return this.extensionFactor;
  }

  get numPortsTotal() {
    return this.quantityWithOverride * this.numPorts;
  }

  // This property is used with a flag "bundleChildCountFromParentProperty"
  get numPortsWithOverride() {
    return this.quantityWithOverride * this.numPortsWithOverridePerItem;
  }

  get numPortsWithOverridePerItem() {
    return this.numPortsOverride !== null ? this.numPortsOverride : this.numPorts;
  }

  get numPortsOverride() {
    return this._numPortsOverride;
  }

  set numPortsOverride(v) {
    if (v <= this.numPorts) {
      this._numPortsOverride = v;
    }
  }

  get hasNegativeQuantityChild() {
    // This getter is only required for line items with flag stopIfHasChildWithNegativeCount
    // If parent has no flag there is no need to do a children loop
    if (!this.hasFlag('stopIfHasChildWithNegativeCount')) {
      // Return null to strictly indicate that verification does not even happen
      return null;
    }

    // subLineItems is an Array returned by API
    // If it is empty that means parent will never has children in quote
    if (this.subLineItems.length === 0) {
      return false;
    }

    /** @type {[LineItemModel]} */
    const subItems = this.subItems;

    // subItems is an Array of Line Item Models that is added to the quote
    for (let i = 0; i < subItems.length; i++) {
      if (subItems[i].quantityWithOverride < 0) {
        return true;
      }
    }

    return false;
  }

  get numPortsWithOverrideOM() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!quote) {
      return null;
    }

    return quote.overnightGuarantee === true ? this.numPortsWithOverride : 0;
  }

  get numPortsWithOverrideOMW() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!quote) {
      return null;
    }

    return quote.overnightGuarantee === true ? 0 : this.numPortsWithOverride;
  }

  get numPortsWithOverridePEP() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!quote) {
      return null;
    }

    return quote.perExtensionPricing === true ? this.numPortsWithOverride : 0;
  }

  get _locationSiblingsLookup() {
    let result = [];
    /** @type {QuotesCollectionModel} */
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (quotes) {
      const locationQuotes = quotes.locationQuotes;
      const locationsCount = locationQuotes.length;

      for (let i = 0; i < locationsCount; i++) {
        /** @type {LocationQuoteModel} */
        const quote = locationQuotes[i];
        const siblingItem = quote.lineItems.items.find(d => d.parentUuid === this._uuid);

        if (!siblingItem) {
          if (config.showConsoleLog) {
            console.warn(this.className + '.locationSiblings: Unable to find location sibling model.');
          }

          continue;
        }

        result.push(siblingItem);
      }
    }

    return result;
  }

  get locationSiblings() {
    return this._getCachedValueOnExport('_locationSiblingsLookup');
  }

  get _finalizedLocationSiblingsLookup() {
    return this.locationSiblings.filter(
      /** @type {@LocationLineItemModel} */ d => {
        /** @type {LocationQuoteModel} */
        const quote = d.findSibling(i => i instanceof LocationQuoteModel);

        if (quote) {
          return quote.quoteStatus === QUOTE_STATUS.FINALIZED;
        }

        return false;
      }
    );
  }

  get _finalizedLocationSiblings() {
    return this._getCachedValueOnExport('_finalizedLocationSiblingsLookup');
  }

  get _allocatedLookup() {
    let result = 0;
    const locationSiblings = this.locationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      result += sibling.quantityWithOverride;
    }

    return result;
  }

  get allocated() {
    return this._getCachedValueOnExport('_allocatedLookup');
  }

  get _allocatedFinalizedLookup() {
    let result = 0;
    const locationSiblings = this._finalizedLocationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      result += sibling.quantityWithOverride;
    }

    return result;
  }

  get allocatedFinalized() {
    return this._getCachedValueOnExport('_allocatedFinalizedLookup');
  }

  get _allocatedOverrideLookup() {
    let result = null;
    const locationSiblings = this.locationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      if (sibling.overrideQuantity !== null) {
        if (result === null) {
          result = 0;
        }

        result += sibling.overrideQuantity;
      }
    }

    return result;
  }

  get allocatedOverride() {
    return this._getCachedValueOnExport('_allocatedOverrideLookup');
  }

  get quantityLessThanAllocatedFinalized() {
    return (
      this.quantityWithOverride < this.allocatedFinalized &&
      this.catalogFlags.some(f => ['ictppsItem', 'skipRollUp'].includes(f.flag.name)) === false
    );
  }

  get markupValue() {
    this.cellTypes.markupValue = this.allocatedFinalized ? CELL_TYPES.amountForceDisabled : this._cellTypes.markupValue;

    return this._markupValue;
  }

  set markupValue(value) {
    this._markupValue = Number(value) || 0;
  }

  get overrideValue() {
    this.cellTypes.overrideValue = this.allocatedFinalized
      ? CELL_TYPES.amountForceDisabled
      : this._cellTypes.overrideValue;

    return this._overrideValue;
  }

  set overrideValue(value) {
    this._overrideValue = value === null ? null : Number(value) || 0;
  }

  get overrideQuantity() {
    this.cellTypes.overrideQuantity = this.allocatedFinalized
      ? CELL_TYPES.numberForceDisabled
      : this._cellTypes.overrideQuantity;

    return this._overrideQuantity;
  }

  set overrideQuantity(value) {
    this._overrideQuantity = value === null ? null : Number(value) || 0;
  }

  get invalidSolutionType() {
    /** @type {CustomerOrderModel} */
    const quote = this.findSibling(d => d instanceof CustomerOrderModel);

    if (!quote || quote.orderType !== ORDER_TYPES.ADD_ON) {
      return false;
    }

    return !this.applicableSolutionTypes.includes(quote.solutionTypeId);
  }

  get showSolutionTypePrefix() {
    /** @type {CustomerOrderModel} */
    const quote = this.findSibling(d => d instanceof CustomerOrderModel);

    if (!quote || PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return quote.orderType !== ORDER_TYPES.ADD_ON ? true : this.invalidSolutionType;
  }

  /**
   * Validates allowedToAddOnCondition flags against Master Order data or calculation results.
   * OR logic is used. Loop breaks on very first successful match.
   * If no flags present return value may be true.
   *
   * @returns {boolean}
   */
  allowedToAddOnConditionFlagIsValid() {
    if (this.masterOrder) {
      return this.constructor.validateAllowedToAddOnConditionFlags({
        lineItemFlags: this.catalogFlags,
        productId: this.productId,
        masterOrderModel: this.masterOrder,
      });
    }

    return true;
  }

  /**
   * Validates allowedToAddOnCondition flags against Master Order data or calculation results.
   * OR logic is used. Loop breaks on very first successful match.
   * If no flags present return value may be true or null. Depends on returnNullIfNoFlags attribute.
   *
   * @param {object} lineItemFlags
   * @param {number} productId
   * @param {CustomerOrderModel} masterOrderModel
   * @param {boolean} returnNullIfNoFlags
   * @returns {boolean|null}
   */
  static validateAllowedToAddOnConditionFlags({
    lineItemFlags,
    productId,
    masterOrderModel,
    returnNullIfNoFlags = false,
  }) {
    return this._validateConditionsVersusSpecifiedFlagName({
      flagName: 'allowedToAddOnCondition',
      lineItemFlags,
      productId,
      quoteModel: masterOrderModel,
      returnNullIfNoFlags,
    });
  }

  // TODO: It seems like productId used for "lineItem." validation support
  //       should be an optional and not fail validation in case of use in Add Line Item dialog
  //       Review and refactor.
  static _validateConditionsVersusSpecifiedFlagName({
    flagName,
    lineItemFlags,
    productId,
    quoteModel,
    returnNullIfNoFlags = false,
  }) {
    const flags = lineItemFlags.filter(f => f.flag.name === flagName);

    // No flags specified for line item
    // Or no quote model available
    if (flags.length === 0 || !quoteModel) {
      // Return null if returnNullIfNoFlags is set otherwise true
      return returnNullIfNoFlags ? null : true;
    }

    // Results type conversion mapping
    // Key is a typeof expectedValue
    // The value is a type to be used with CalcResultsModel.getValue(..., type)
    const resultsTypeConversionMap = {
      number: 'float',
      boolean: 'boolean',
      string: 'string',
    };

    for (let i = 0; i < flags.length; i++) {
      const f = flags[i];
      const nameValuesCollection = f.valueAsString.split(';');
      const validationResultForEachNameValuePair = [];

      for (const nameValuePair of nameValuesCollection) {
        const [propName, expectedValueString] = nameValuePair.split(',');

        if (typeof expectedValueString === 'undefined') {
          continue;
        }

        const expectedValue = TypeConverter(expectedValueString);
        const propNameResultsMatch = propName.match(/^results\.(.+)/);

        // Validate expected value against calculation results
        if (propNameResultsMatch !== null) {
          const dataPoint = propNameResultsMatch[1];

          // In case of expectedValue === null, CalcResultsModel.getValue(..., undefined) will happen
          // It is OK because getValue will use default type in such case (string)
          const dataPointValue = quoteModel.calcResults.getValue(
            dataPoint,
            resultsTypeConversionMap[typeof expectedValue]
          );

          validationResultForEachNameValuePair.push(expectedValue === dataPointValue);
          continue;
        }

        const propNameLineItemMatch = propName.match(/^lineItem\.(.+)/);

        // Validate expected value against Line item property value
        if (propNameLineItemMatch !== null) {
          const propName = propNameLineItemMatch[1];
          const lineItemInQuote = quoteModel.lineItems.items.find(item => item.productId === productId);

          if (typeof lineItemInQuote === 'undefined') {
            validationResultForEachNameValuePair.push(false);
            continue;
          }

          validationResultForEachNameValuePair.push(expectedValue === lineItemInQuote[propName]);
          continue;
        }

        // Validate expected value against Quote Model data
        let quoteModelValue = quoteModel[propName];

        if (typeof quoteModelValue === 'undefined') {
          validationResultForEachNameValuePair.push(false);
          continue;
        }

        if (!Array.isArray(quoteModelValue)) {
          quoteModelValue = [quoteModelValue];
        }

        if (quoteModelValue.includes(expectedValue)) {
          validationResultForEachNameValuePair.push(true);
          continue;
        }

        validationResultForEachNameValuePair.push(false);
      }

      // TODO: It seems like this method may use fail early approach. Review and optimize.
      const result = validationResultForEachNameValuePair.every(res => res === true);

      if (result) {
        return true;
      }
    }

    return false;
  }

  get partnerProductName() {
    this.cellTypes.partnerProductName = this._cellTypes.partnerProductName;

    if (this.getFlagValue('placeholderProduct') === 'TRUE') {
      this.cellTypes.partnerProductName = this.allocatedFinalized ? CELL_TYPES.textForceDisabled : CELL_TYPES.text;
    }

    return this._partnerProductName;
  }

  set partnerProductName(value) {
    this._partnerProductName = value;
  }

  // TODO: Rename method to isUniquePerQuote.
  /**
   * Indicates can item be added only once or multiple times
   * @returns {boolean}
   */
  get isUniqueByLineItemId() {
    // TODO: Add verification for partner added line items. They are not unique either
    return this.getFlagValue('placeholderProduct') !== 'TRUE';
  }

  get _allocatedPriceLookup() {
    let result = 0;
    // TODO find out why we get 'null' in this.locationSiblings
    const locationSiblings = this.locationSiblings || [];

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      result += sibling.price;
    }

    return result;
  }

  get allocatedPrice() {
    return this._getCachedValueOnExport('_allocatedPriceLookup');
  }

  set markup(value) {
    if (!PriceBookContext.model.flags.hidePriceAndMarkup || typeof value !== 'number') {
      return;
    }

    this._markupValue = value;
  }

  get existing() {
    return this._existing;
  }

  setExisting(value) {
    this._existing = Boolean(value);
  }

  get recurring() {
    if (typeof this._recurring !== 'boolean') {
      this._recurring = apiData.categories.find(d => d.id === this.lineItemCategoryId).recurring;
    }

    return this._recurring;
  }

  get _isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable() {
    if (!this.getFlag('invalidateItemsNotAllowedToAddOnCondition')) {
      return false;
    }

    return Boolean(!this.allowedToAddOnConditionFlagIsValid() && this.quantityWithOverride);
  }

  get isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable() {
    return this._getCachedValueOnExport('_isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable');
  }

  get quantityWithOverrideIfConcurrent() {
    return this.masterOrder.contactCenterConcurrency || this.masterOrder.sangomaCXConcurrency
      ? this.quantityWithOverride
      : 0;
  }
}

export default LineItemModel;
