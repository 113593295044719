import React from 'react';
import { ToggleControl } from '../../Controls';
import { ADDENDUM_TYPES_LABEL } from '../../../common/enums';
import { string, bool, object, func } from 'prop-types';

const AddendumBlock = ({
  signedOn,
  documentType,
  active,
  fields,
  disabledState,
  lockedState,
  onChange,
  getAddendumFields,
}) => {
  const disabled = disabledState || !!signedOn;
  const onAddendumStatusChangeHandler = React.useCallback(
    value => onChange(value, documentType),
    [documentType, onChange]
  );

  return (
    <div className="addendums-block" key={documentType}>
      <div className="left-section">
        <ToggleControl
          id={documentType}
          key={documentType}
          label={ADDENDUM_TYPES_LABEL[documentType]}
          hint={signedOn ? 'msg_addendum_already_signed' : ''}
          value={active}
          onChange={onAddendumStatusChangeHandler}
          disabledState={disabled}
          lockedState={lockedState}
        />
      </div>
      <div className="right-section">{active && <div>{getAddendumFields(fields, documentType, disabled)}</div>}</div>
    </div>
  );
};

AddendumBlock.propTypes = {
  signedOn: string,
  documentType: string,
  active: bool,
  fields: object,
  disabledState: bool,
  lockedState: bool,
  onChange: func,
  getAddendumFields: func,
};

export default AddendumBlock;
