import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dates from './nested/Dates';
import Providers from './nested/Providers';

class Ictp extends Component {
  static propTypes = {
    hideDates: PropTypes.bool,
    providers: PropTypes.object,
    date: PropTypes.object,
    lockedState: PropTypes.bool,
    isLocationQuote: PropTypes.bool,
    setMixed: PropTypes.func,
    mixedDealerNet: PropTypes.object,
    orderType: PropTypes.string,
    salesOps: PropTypes.bool,
    openDetails: PropTypes.func,
    disableIctpps: PropTypes.bool,
    datesDisabled: PropTypes.bool,
    providersDisabled: PropTypes.bool,
    confirmationBoxVisible: PropTypes.bool,
    confirmationBoxDisabled: PropTypes.bool,
    disabledState: PropTypes.bool,
    dates: PropTypes.object,
  };

  static defaultProps = {
    providers: {},
    dates: {},
    lockedState: false,
    disabledState: false,
    isLocationQuote: false,
  };

  render() {
    const {
      dates,
      hideDates,
      lockedState,
      providers,
      isLocationQuote,
      // setMixed,
      orderType,
      salesOps,
      openDetails,
      disableIctpps,
      datesDisabled,
      providersDisabled,
      confirmationBoxVisible,
      confirmationBoxDisabled,
    } = this.props;

    return (
      <div>
        <Dates
          {...dates}
          hideDates={hideDates}
          lockedState={lockedState}
          disabledState={datesDisabled}
          openDetails={openDetails}
          disableIctpps={disableIctpps}
        />
        <Providers
          {...providers}
          lockedState={lockedState}
          disabledState={providersDisabled}
          isLocationQuote={isLocationQuote}
          orderType={orderType}
          salesOps={salesOps}
          confirmationBoxVisible={confirmationBoxVisible}
          confirmationBoxDisabled={confirmationBoxDisabled}
          // KM-4539 Mixed ICTP
          // locked={locked || providers.mixed}
          // mixed={providers.mixed}
          // setMixed={setMixed}
          // mixedDealerNet={providers.mixedDealerNet}
        />
      </div>
    );
  }
}

export default Ictp;
