import { CustomerOrderModel, LineItemModel, ModelAbstract, QuotesCollectionModel } from '../index';
import apiData from '../../Storage/apiData';
import { ERROR_CONTEXT_TYPES, ORDER_TYPES, USER_ROLES } from '../../common/enums';
import { AuthContext, ErrorContext } from '../../Context';
import fixFloat from '../../common/helpers/fixFloat';
import config from '../../config';
import bindMultiple from '../../common/helpers/bindMultiple';
import { AddExistingDialogModel } from './index';

// TODO: Make use of this model for regular line items and make controller cleanup
class AddDialogModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this._ignoreOnExport.push('quotesCollection', 'masterOrder');
    this._headers = [];
    this._categoryId = null;
    this._opened = false;
    this._selectedDevices = [];

    this.catalog = [];
    this.hasBundles = false;
    this.showSolutionTypeMark = false;

    bindMultiple(this, this.onClose, this.onSelect);
  }

  get isForExistingItems() {
    return false;
  }

  /**
   * @returns {QuotesCollectionModel|null}
   */
  get quotesCollection() {
    return this.findSibling(instance => instance instanceof QuotesCollectionModel);
  }

  /**
   * @returns {CustomerOrderModel|null}
   */
  get masterOrder() {
    return this.findSibling(instance => instance instanceof CustomerOrderModel);
  }

  get categoryId() {
    return this._categoryId;
  }

  set categoryId(newValue) {
    const wipeHeadersCache = this._categoryId !== newValue;

    this._categoryId = newValue;

    if (wipeHeadersCache) {
      this._headers = null;
    }
  }

  get opened() {
    return this._opened;
  }

  set opened(newState) {
    // KM-5926: Catalog cache should be disabled for promotion line items
    //          Because selectable value changes depending on contract term months value
    // KM-6072: forbidAddingIfPropertyEqualsTrue flag also requires disabled dialog cache
    // TODO: Make a discovery, is caching even possible with current business logic
    // Rebuild catalog each time "opened" changes it's state from false to true
    const wipeCatalogCache = newState && !this._opened;

    this._opened = newState;

    if (wipeCatalogCache) {
      this.rebuildCatalog();
    }
  }

  get title() {
    return (apiData.categories.find(d => d.id === this.categoryId) || {}).name;
  }

  get isRecurring() {
    return (apiData.categories.find(d => d.id === this.categoryId) || {}).recurring;
  }

  get showEmptyDialogHint() {
    return !this.catalog.length;
  }

  get suppressedIds() {
    return this.masterOrder.suppressedLineItemIds;
  }

  /**
   * @returns {Array}
   */
  get partNumbersFromOriginalQuote() {
    if (this.masterOrder.availableLineProductGpCode === null) {
      return [];
    }

    return Array.isArray(this.masterOrder.availableLineProductGpCode)
      ? this.masterOrder.availableLineProductGpCode
      : [this.masterOrder.availableLineProductGpCode];
  }

  get selectedDevices() {
    return this._selectedDevices;
  }

  /**
   * @param {LineItemModel[]} arrayOfLineItemModels
   */
  set selectedDevices(arrayOfLineItemModels) {
    this._selectedDevices = arrayOfLineItemModels.map(d => ({ lineItemId: d.lineItemId }));
  }

  onClose(suppressedIds, triggerChangeEvent = true) {
    this.catalog = [];
    this.opened = false;
    this.selectedDevices = [];

    if (Array.isArray(suppressedIds) && !(this instanceof AddExistingDialogModel)) {
      this.masterOrder.suppressedLineItemIds = suppressedIds;
    }

    if (triggerChangeEvent) {
      this.modelChanged(this);
    }
  }

  onSelect({ lineItemIds, suppressedIds }) {
    let showMsTeamsWarning = false;

    for (let i = 0; i < lineItemIds.length; i++) {
      const lineItemId = lineItemIds[i];
      const data = apiData.lineItems.find(d => d.lineItemId === lineItemId);

      if (!data) {
        continue;
      }

      if (this.masterOrder.orderType === ORDER_TYPES.REDUCTION) {
        data.quantity = 0;
      }

      const doFocus = i === lineItemIds.length - 1;
      const addFunction =
        this instanceof AddExistingDialogModel ? this.masterOrder.lineItems.addExisting : this.masterOrder.lineItems.add;

      /** @type {LineItemModel} */
      const lineItem = addFunction({
        ...data,
        doFocus,
        focusField: 'quantity',
        existing: this instanceof AddExistingDialogModel,
      });

      if (lineItem.hasProductTag('notSupportedOnSC2Locations')) {
        showMsTeamsWarning = true;
      }
    }

    const locationModels = this.masterOrder.locations;

    for (let i = 0; i < locationModels.length; i++) {
      locationModels[i].syncLineItems();
    }

    this.onClose(suppressedIds, false);

    if (showMsTeamsWarning) {
      ErrorContext.model.setProps({
        isShown: true,
        details: 'msg_ms_teams_star_center_2_warning',
        autoHideDelay: 0,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.WARNING,
      });
    }

    this.modelChanged(this);
  }

  rebuildCatalog() {
    const categoryId = this.categoryId;

    const lineItemsApiData = JSON.parse(JSON.stringify(apiData.lineItems));

    const selectedQuoteIndex = this.quotesCollection.selectedQuote.index;
    const performSolutionTypeFilter = Boolean(selectedQuoteIndex) || this.masterOrder.orderType === ORDER_TYPES.ADD_ON;
    const solutionTypeId = this.quotesCollection.items[selectedQuoteIndex].solutionTypeId;

    const catalog = lineItemsApiData.filter(lineItemApiData => {
      if (
        lineItemApiData.lineItemCategoryId !== categoryId ||
        !lineItemApiData.active ||
        !lineItemApiData.allowedToAdd
      ) {
        return false;
      }

      // KM-8584: Filter out suppressed items for non SalesOps users
      if (
        AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER &&
        this.masterOrder.suppressedLineItemIds.includes(lineItemApiData.lineItemId)
      ) {
        return false;
      }

      // KM-5766: Does not show line items with flag isFixedOnUi in Add Line Items dialog
      const isFixedOnUi = lineItemApiData.catalogFlags.find(f => f.flag.name === 'isFixedOnUi');

      if (isFixedOnUi?.valueAsString?.toLowerCase() === 'true') {
        return false;
      }

      // KM-6125: Filter out items that has specificForPackageX
      // But not match currently selected package ID
      let hasAnyPerPackageFlag = false;

      for (let i = 1; i <= 4; i++) {
        if (lineItemApiData.catalogFlags.some(f => f.flag.name === 'specificForPackage' + i)) {
          hasAnyPerPackageFlag = true;
          break;
        }
      }

      if (hasAnyPerPackageFlag) {
        const specificForPackageFlag = lineItemApiData.catalogFlags.find(
          f => f.flag.name === 'specificForPackage' + this.masterOrder.packageIdSelected
        );

        if (
          !specificForPackageFlag ||
          !specificForPackageFlag.valueAsString ||
          specificForPackageFlag.valueAsString.toLowerCase() === 'false'
        ) {
          return false;
        }
      }

      const forbidAddingIfPropertyEqualsTrue = lineItemApiData.catalogFlags.find(
        f => f.flag.name === 'forbidAddingIfPropertyEqualsTrue'
      );

      if (forbidAddingIfPropertyEqualsTrue) {
        // Reverse logic if model property equals true it should not be selectable
        // filter function should return false
        if (this.masterOrder[forbidAddingIfPropertyEqualsTrue.valueAsString] === true) {
          return false;
        }
      }

      // KM-6482: Filter line items by telecom solution type
      // When Add Line Item dialog shown on location quote level
      if (performSolutionTypeFilter && !lineItemApiData.applicableSolutionTypes.includes(solutionTypeId)) {
        return false;
      }

      // KM-6391: Gather MO getters from allowedToAddOnCondition flag
      // Evaluate them to match valueAsString condition
      const allowedToAddOnConditionResult = LineItemModel.validateAllowedToAddOnConditionFlags({
        lineItemFlags: lineItemApiData.catalogFlags,
        productId: lineItemApiData.productId,
        masterOrderModel: this.masterOrder,
        returnNullIfNoFlags: true,
      });

      // TODO: If "selectable" check will be moved to the top then remove "if (typeof ...) {}" scope
      if (typeof allowedToAddOnConditionResult === 'boolean') {
        if (allowedToAddOnConditionResult === false) {
          // KM-11207: Show line item in Add Line Item dialog if it has flag previewItemsNotAllowedToAddOnCondition
          return lineItemApiData.catalogFlags.some(
            flagApiData =>
              flagApiData.flag.name === 'previewItemsNotAllowedToAddOnCondition' &&
              flagApiData.valueAsString.toLowerCase() === 'true'
          );
        }

        return true;
      }

      const onlyAllowedAsExistingItem = lineItemApiData.catalogFlags.some(
        flagApiData =>
          flagApiData.flag.name === 'productTag' && flagApiData.valueAsString === 'onlyAllowedAsExistingItem'
      );

      if (onlyAllowedAsExistingItem && !this.isForExistingItems) {
        return false;
      }

      // TODO: "selectable" check should probably be moved to very top on par with "active"
      //       But we need to ensure there are no cases where:
      //       (allowedToAddOnConditionResult === true && lineItemApiData.selectable === false)
      return lineItemApiData.selectable;
    });

    // KM-5155: In case bundle item has zero dealerNet
    // Calc bundle price as a total recursive SUM of its' sub items dealerNet values
    for (let i = 0; i < catalog.length; i++) {
      let item = catalog[i];

      item._bundleDealerNet = item.bundleType === 'COMPOSITE' ? this._calcBundleDealerNet(item) : null;

      if (item._bundleDealerNet > 0 && !this.hasBundles) {
        this.hasBundles = true;
      }

      // KM-11207: Items with allowedToAddOnCondition === false && previewItemsNotAllowedToAddOnCondition === true flags
      //           should have disabled checkboxes
      const allowedToAddOnCondition = LineItemModel.validateAllowedToAddOnConditionFlags({
        lineItemFlags: item.catalogFlags,
        // TODO: Comparing against "lineItem." may have unexpected effect in Add Line Item dialog
        productId: item.productId,
        masterOrderModel: this.masterOrder,
      });
      const previewItemsNotAllowedToAddOnCondition = item.catalogFlags.some(
        flagApiData =>
          flagApiData.flag.name === 'previewItemsNotAllowedToAddOnCondition' &&
          flagApiData.valueAsString.toLowerCase() === 'true'
      );

      item._forceDisableItemSelection = !allowedToAddOnCondition && previewItemsNotAllowedToAddOnCondition;
    }

    this.catalog = catalog;

    let isSolutionTypeSame = true;

    // No need to do isSolutionTypeSame validation if there is no or one item available
    if (this.catalog.length > 1) {
      const firstItemApplicableSolutionTypes = this.catalog[0].applicableSolutionTypes;

      for (let i = 0; i < this.catalog.length; i++) {
        const lineItem = this.catalog[i];
        const solutionTypesMatches =
          firstItemApplicableSolutionTypes.length === lineItem.applicableSolutionTypes.length &&
          firstItemApplicableSolutionTypes.every(solutionType =>
            lineItem.applicableSolutionTypes.includes(solutionType)
          );

        if (!solutionTypesMatches) {
          isSolutionTypeSame = false;
          break;
        }
      }
    }

    // Show solution type markers only in case there were no filtering done
    this.showSolutionTypeMark = performSolutionTypeFilter === false && !isSolutionTypeSame;
  }

  _calcBundleDealerNet(parent, level = 0) {
    let dealerNet = 0;
    const subLineItems = parent.subLineItems;

    if (!subLineItems.length) {
      // Top level item is not a bundle
      if (level === 0) {
        return null;
      }

      // Else return child's dealerNet value
      return parent.dealerNet;
    }

    const isParentRecurring = this._isLineItemRecurring(parent);

    // Bundle has it's own dealerNet
    if (parent.dealerNet !== 0) {
      // Return null for bundle price if it is a top level item
      if (level === 0) {
        return null;
      }

      // Else add bundle dealerNet value into SUM
      dealerNet = fixFloat(dealerNet) + fixFloat(parent.dealerNet);
    }

    for (let i = 0; i < subLineItems.length; i++) {
      const childId = subLineItems[i].subLineItemId;
      const child = apiData.lineItems.find(d => d.lineItemId === childId);

      // If child not found it indicates DB broken links
      if (!child) {
        if (config.showConsoleLog) {
          console.warn(
            `Line Item [${parent.lineItemId}] has sub Line Item [${childId}] link. But child data missing in API response.`
          );
        }

        continue;
      }

      const isChildRecurring = this._isLineItemRecurring(child);

      // [Non]Recurring type of parent and child should match
      if (isParentRecurring !== isChildRecurring) {
        // Skip child item if types does not match
        continue;
      }

      dealerNet += this._calcBundleDealerNet(child, level + 1);
    }

    return dealerNet;
  }

  _isLineItemRecurring(lineItem) {
    const category = apiData.categories.find(d => d.id === lineItem.lineItemCategoryId);

    return category === undefined ? null : category.recurring;
  }

  get headers() {
    if (this._headers !== null) {
      return this._headers;
    }

    if (apiData.partnerCategoryIds.includes(this.categoryId)) {
      this._headers = [
        {
          label: '',
          name: 'emptyBlock',
          sortable: false,
        },
        {
          label: 'msg_name',
          name: 'lineItemName',
          type: 'string',
          className: 'header-name',
        },
        {
          label: '',
          name: 'emptyBlock',
          sortable: false,
        },
      ];
    } else {
      this._headers = [
        {
          label: '',
          name: 'emptyBlock',
          sortable: false,
        },
        {
          label: 'msg_name',
          name: 'lineItemName',
          type: 'string',
          className: 'header-name',
          sortable: true,
        },
        {
          label: 'msg_add_li_dialog_header_brand',
          name: 'brandName',
          type: 'string',
          className: 'header-brand',
          sortable: true,
        },
        {
          label: 'msg_type',
          name: 'lineItemSubCategoryName',
          type: 'string',
          className: 'header-type',
          sortable: true,
        },
        {
          label: 'msg_price',
          name: 'dealerNet',
          type: 'number',
          className: 'header-price',
          sortable: true,
        },
        {
          label: 'msg_slick',
          name: 'slick',
          type: 'string',
          className: 'header-slick',
          sortable: true,
        },
      ];
    }

    if (AuthContext.model.hasSalesOpsPermissions && !this.isForExistingItems) {
      const headerClassName = apiData.partnerCategoryIds.includes(this._categoryId)
        ? 'header-center-aligned'
        : 'header-suppress';

      this._headers.push({
        label: 'msg_suppress',
        name: 'suppress',
        type: 'string',
        className: headerClassName,
        sortable: false,
      });
    }

    return this._headers;
  }

  // TODO: Remove this getter with refactoring mentioned in src/components/QuoteDeviceCatalog/index.js line 437
  //       Refactoring related to 'catalog-list-header-sales-ops' CSS selector.
  get isSalesOps() {
    return AuthContext.model.hasSalesOpsPermissions;
  }
}

export default AddDialogModel;
