import { finalizeBtnTooltipText } from '../../common/check-required-for-finalize';
import { checkRequiredFieldsForPrint } from '../../common/check-required-for-print';
import ToolbarButtonModel from './ToolbarButtonModel';

class PreliminaryModel extends ToolbarButtonModel {
  get disabled() {
    return Boolean(
      this.requiredFields?.fields.length ||
        !this.appState.isPrintAllowed ||
        super.disabled ||
        this.appState.switchvoxWlwDisabled
    );
  }

  get errors() {
    if (!this.appState.isSaved || this.hasRequiredFieldsError || this.appState.isSaveAllowed) {
      return finalizeBtnTooltipText(this.requiredFields);
    }

    return '';
  }

  get errorsCnt() {
    return this.requiredFields ? this.requiredFields.fields.length : 0;
  }

  get label() {
    return this.masterOrder.existingAlsAgreement ? 'msg_quick_order' : 'msg_quick_quote';
  }

  get requiredFields() {
    return checkRequiredFieldsForPrint(
      this.masterOrder,
      this.appState.errors.salesExceptionsRules,
      this.controller.rollUpDisabled
    );
  }

  get hasRequiredFieldsError() {
    if (!this.requiredFields) {
      return false;
    }

    return this.requiredFields.fields.length || this.requiredFields.seRulesMessage !== '';
  }
}

export default PreliminaryModel;
