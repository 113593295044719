import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { ORDER_TYPES } from '../../common/enums';
import LocationSelect from './LocationSelect';
import './css/locations-tab.css';

class Tab extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    tab: PropTypes.object.isRequired,
    label: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    this.props.onClick(this.props.tab);
  }

  render() {
    const { btnClassName, label } = this.props;

    return (
      <div className="tab-wrapper">
        <button type="button" title={label} className={btnClassName} onClick={this.onClickHandler}>
          {label}
        </button>
      </div>
    );
  }
}

class LocationsTab extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAddDisabled: PropTypes.bool,
    activeLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectLocation: PropTypes.func.isRequired,
    onAddLocation: PropTypes.func.isRequired,
    showPlusButton: PropTypes.bool,
    showLocationMenuButton: PropTypes.bool,
  };

  static defaultProps = {
    isAddDisabled: false,
    showPlusButton: true,
    showLocationMenuButton: true,
  };

  leftBtnElem = null;
  rightBtnElem = null;
  tabWrapperElem = null;
  componentElem = null;
  scrollListenerAdded = false;

  constructor(props) {
    super(props);

    this.scrollLeftBtn = this.scrollLeftBtn.bind(this);
    this.scrollRightBtn = this.scrollRightBtn.bind(this);
    this.checkingBtnsDisabling = this.checkingBtnsDisabling.bind(this);
  }

  checkingTabsScrolling(prevProps, isResize = false) {
    if (this.tabWrapperElem.children.length > 0 && this.componentElem) {
      let tabsWidth = 0;
      let scrollLeft = 0;
      let wrapperStyles = this.tabWrapperElem.getBoundingClientRect();
      let cnt = this.tabWrapperElem.children.length;
      // Always check scroll position if it is resize event
      let isNewActiveTab = isResize || prevProps.activeLocationId !== this.props.activeLocationId;
      for (let i = 0; i < cnt; i++) {
        if (isNewActiveTab && this.tabWrapperElem.children[i].querySelector('.active-tab')) {
          scrollLeft = tabsWidth;
        }
        tabsWidth += this.tabWrapperElem.children[i].getBoundingClientRect().width;
      }

      if (Math.ceil(wrapperStyles.width) < Math.ceil(tabsWidth)) {
        if (!this.scrollListenerAdded) {
          this.componentElem.classList.add('show-arrows');
          this.leftBtnElem = this.componentElem.querySelector('button.btn-left');
          this.rightBtnElem = this.componentElem.querySelector('button.btn-right');
          this.scrollListenerAdded = true;

          if (this.leftBtnElem) {
            this.leftBtnElem.disabled = this.tabWrapperElem.scrollLeft === 0;
          }
          this.tabWrapperElem.addEventListener('scroll', this.checkingBtnsDisabling);
        }

        if (isNewActiveTab) {
          this.tabWrapperElem.scrollLeft = scrollLeft;
        }
      } else {
        if (this.scrollListenerAdded) {
          this.scrollListenerAdded = false;
          this.componentElem.classList.remove('show-arrows');

          this.tabWrapperElem.removeEventListener('scroll', this.checkingBtnsDisabling);
        }
      }
    }
  }

  checkingBtnsDisabling(e) {
    if (this.leftBtnElem && this.rightBtnElem) {
      this.leftBtnElem.disabled = e.target.scrollLeft === 0;
      this.rightBtnElem.disabled = e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth;
    }
  }

  componentDidMount() {
    this.tabWrapperElem = document.querySelector('.locations-tab-wrapper');
    window.addEventListener('resize', this.checkingTabsScrolling.bind(this, null, this.props, true));
  }

  componentDidUpdate(prevProps) {
    this.checkingTabsScrolling(prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkingTabsScrolling.bind(this, null, this.props, true));
  }

  render() {
    const {
      tabs,
      isAddDisabled,
      onAddLocation,
      showPlusButton,
      showLocationMenuButton,
      activeLocationId,
      onSelectLocation,
      intl,
    } = this.props;

    const locationsTab = tabs.map(tab => {
      const isActive =
        tab.locationInfo.locationQuoteId === activeLocationId ||
        (tab.locationInfo.isOrderTab && !tab.locationInfo.locationQuoteId && !activeLocationId);

      let btnClassNames = classNames({
        'location-tab': true,
        'active-tab': isActive,
        large: true,
        'order-tab': tab.locationInfo.isOrderTab,
        tertiary: !isActive,
      });

      return (
        <Tab
          tab={tab}
          key={tab.locationInfo.locationQuoteId}
          id={tab.locationInfo.locationQuoteId}
          tabClassName={!!tab.locationInfo.errors ? 'warning-tab' : ''}
          btnClassName={btnClassNames}
          onClick={onSelectLocation}
          label={
            tab.orderType === ORDER_TYPES.ADD_ON && tab.id === null && tab.isRevisionOfQuoteId === null
              ? 'Add-On - ' + tab.serviceInfo.locationName
              : tab.serviceInfo.locationName
          }
        />
      );
    });

    return (
      <div className="locations-tab-component" ref={el => (this.componentElem = el)}>
        <div className="locations-tabs-block">
          {locationsTab.slice(0, 1)}

          <button
            type="button"
            className="locations-scroll-btn btn-left square large tertiary"
            onClick={this.scrollLeftBtn}
          >
            <span className="icon-chevron-left" />
          </button>

          <div className="locations-tab-wrapper">{locationsTab.splice(1)}</div>

          <button
            type="button"
            className="locations-scroll-btn btn-right square large tertiary"
            onClick={this.scrollRightBtn}
          >
            <span className="icon-chevron-right" />
          </button>
        </div>

        <div className="locations-tab-actions">
          {showPlusButton && (
            <button
              type="button"
              className="add-location square primary"
              onClick={onAddLocation}
              disabled={isAddDisabled}
              title={intl.formatMessage({ id: 'msg_add_location' })}
            >
              <span className="icon-add" />
            </button>
          )}
          {showLocationMenuButton && (
            <LocationSelect locations={tabs} onChange={onSelectLocation} activeLocationId={activeLocationId} />
          )}
        </div>
      </div>
    );
  }

  scrollLeftBtn() {
    this.tabWrapperElem.scrollLeft -= 140;
  }

  scrollRightBtn() {
    this.tabWrapperElem.scrollLeft += 140;
  }
}

export default injectIntl(LocationsTab);
