import $http from '../common/$http';
import { OrderInitiationModel } from '../Models';
import AuthContextModel from '../Context/AuthContext';
import IntegrationApi from '../api/IntegrationApi';
import AppController from './AppController';
import { AppStateContext } from '../Context';
import apiData from '../Storage/apiData';
import { ORDER_TYPE_OPTIONS } from '../common/enums';
import config from '../config';
import bindMultiple from '../common/helpers/bindMultiple';
import AppRouter from '../AppRouter/AppRouter';

class OrderInitiationController extends AppController {
  static quoteTypes = {
    1: 'new-customer',
    2: 'new-locations',
    3: 'add-on',
    4: 'rewrite',
    5: 'reduction',
  };

  static quoteTypeIndexes = Object.fromEntries(
    Object.entries(OrderInitiationController.quoteTypes).map(([index, type]) => [type, Number(index)])
  );

  constructor(viewComponent) {
    super(viewComponent);

    this.__inited = false;
    this._initialInputFocusDone = false;
    this._dataFetchingCounter = 0;
    this.partnersFetching = false;
    this.customersFetching = false;
    this.locationsFetching = false;
    this.bcFetchingForLocation = null;
    this.originalQuoteId = null;
    this.resetOverrides = true;

    /** @type {OrderInitiationModel} */
    this.order = new OrderInitiationModel(this);
    this.order.actions = {
      [ORDER_TYPE_OPTIONS.NEW_CUSTOMER]: OrderInitiationModel.ACTIONS_MSG_LIST[ORDER_TYPE_OPTIONS.NEW_CUSTOMER],
      [ORDER_TYPE_OPTIONS.NEW_LOCATIONS]: OrderInitiationModel.ACTIONS_MSG_LIST[ORDER_TYPE_OPTIONS.NEW_LOCATIONS],
      [ORDER_TYPE_OPTIONS.ADD_ON]: OrderInitiationModel.ACTIONS_MSG_LIST[ORDER_TYPE_OPTIONS.ADD_ON],
      // Rewrite orders are hidden initially
      // [ORDER_TYPE_OPTIONS.REWRITE]: OrderInitiationModel.ACTIONS_MSG_LIST[ORDER_TYPE_OPTIONS.REWRITE],
      // KM-5132: Disable reduction orders
      // [ORDER_TYPE_OPTIONS.REDUCTION]: OrderInitiationModel.ACTIONS_MSG_LIST[ORDER_TYPE_OPTIONS.REDUCTION],
    };

    bindMultiple(
      this,
      this._fetchPartnersByUserId,
      this.onChangeCustomer,
      this.onChangePartner,
      this.onOrderPropChange,
      this.onStartQuoteButtonClick,
      this.onOrderTypeChange,
      this.onChangeLocationIds
    );

    AppStateContext.model.addBroadcastListener(this._fetchPartnersByUserId);
  }

  _init(r) {
    if (this.__inited) {
      return false;
    }

    this.__inited = true;

    this._fetchIndustryTypes();

    this.order.partners = r.reduce((users, user) => {
      user.value = user.billingDealerId;
      user.text = user.dealerName;
      users.push(user);

      return users;
    }, []);

    this.order.partnerLocked = this.order.partners.length === 0;

    if (this.order.partners.length === 1) {
      this.onChangePartner(this.order.partners[0].billingDealerId);
    } else {
      this.renderView();
    }
  }

  _fetchIndustryTypes() {
    const url = config.api.url + '/location/industryCodes';
    this.isDataFetching = true;

    $http.instance.api.get(url).then(data => {
      this.isDataFetching = false;

      if (data?.data?.status === 200) {
        this.order.industryTypes = data.data.content
          .filter(industryType => industryType.active)
          .sort((a, b) => a.sort - b.sort);

        this.renderView();
      }
    });
  }

  get props() {
    return {
      order: this.order.toJS(),
      partnersFetching: this.partnersFetching,
      customersFetching: this.customersFetching,
      locationsFetching: this.locationsFetching,
      isDataFetching: this.isDataFetching,
      doInitialInputFocus: this.doInitialInputFocus,

      onChangeCustomer: this.onChangeCustomer,
      onChangePartner: this.onChangePartner,
      onOrderPropChange: this.onOrderPropChange,
      onStartQuoteButtonClick: this.onStartQuoteButtonClick,
      onChangeOrderType: this.onOrderTypeChange,
      onChangeLocationIds: this.onChangeLocationIds,
    };
  }

  onChangePartner(v) {
    if (v !== this.order.selectedPartnerId) {
      this.order.customers = [];
      this.order.locations = [];
      this.order.selectedCustomerId = null;
      this.order.selectedLocationId = null;
      this.order.selectedLocationIds = null;

      this.order.onChange('selectedPartnerId', v);

      if (v !== null) {
        this.fetchPartnerInfo(v);

        if (this.order.selectedAction !== ORDER_TYPE_OPTIONS.NEW_CUSTOMER) {
          this._getCustomers();
        }
      }

      if (!this.originalQuoteId) {
        this.order.actionOptionsLocked = [];
      }

      if (v === null && this.order.customersLoadedForPartnerId) {
        this.order.customersLoadedForPartnerId = null;
      }
    }
  }

  onOrderTypeChange(v) {
    this.order.onChange('selectedAction', v);

    if (
      v !== ORDER_TYPE_OPTIONS.NEW_CUSTOMER &&
      this.order.customersLoadedForPartnerId !== this.order.selectedPartnerId
    ) {
      this._getCustomers();
    }
  }

  onChangeCustomer(v) {
    if (v !== this.order.selectedCustomerId) {
      this.order.locations = [];
      this.order.selectedLocationId = null;
      this.order.selectedLocationIds = null;

      this.order.onChange('selectedCustomerId', v);
      if (v !== null) {
        this.locationsFetching = true;
        this.renderView();

        if (
          [ORDER_TYPE_OPTIONS.NEW_LOCATIONS, ORDER_TYPE_OPTIONS.ADD_ON, ORDER_TYPE_OPTIONS.REWRITE].includes(
            this.order.selectedAction
          )
        ) {
          this.fetchSwitchvoxSipStation(this.order.selectedPartnerId, v);
          this.fetchIsSangomaCXStandalone(this.order.selectedPartnerId, v);
        }

        IntegrationApi.getLocationsByCustomerId({ customerId: v })
          .then(response => {
            if (response.length >= 1) {
              this.order.locations = response.reduce((locations, location) => {
                location.value = location.id;
                location.text = location.name;
                locations.push(location);

                return locations;
              }, []);
            } else {
              this.order.locations = [];
              this.order.selectedLocationId = null;
              this.order.selectedLocationIds = null;
            }
          })
          .finally(() => {
            this.locationsFetching = false;

            if (this.order.selectedAction === ORDER_TYPE_OPTIONS.NEW_LOCATIONS) {
              this.renderView();

              return;
            }

            this.order.updateValidFields();
            this.renderView();
          });
      }
    }
  }

  _getCustomers() {
    const partnerId = this.order.selectedPartnerId;
    this.customersFetching = true;
    this.renderView();

    IntegrationApi.getCustomersByPartnerId({ partnerId })
      .then(response => {
        this.order.customersLoadedForPartnerId = partnerId;

        if (response.length >= 1) {
          this.order.customers = response.reduce((customers, customer) => {
            customer.value = customer.id;
            customer.text = customer.name;
            customers.push(customer);

            return customers;
          }, []);
        }
      })
      .finally(() => {
        this.customersFetching = false;

        // No customers available for this partner yet
        if (this.order.customers.length === 0) {
          // Lock all Order Types except New/New
          this.order.actionOptionsLocked = [
            ORDER_TYPE_OPTIONS.NEW_LOCATIONS,
            ORDER_TYPE_OPTIONS.ADD_ON,
            ORDER_TYPE_OPTIONS.REWRITE,
          ];

          // Force switch Order Type to New/New
          this.order.selectedAction = ORDER_TYPE_OPTIONS.NEW_CUSTOMER;

          // Force reset Customer and Location selection just in case it was selected previously
          this.order.selectedCustomerId = null;
          this.order.selectedLocationId = null;
          this.order.selectedLocationIds = null;
        }

        this.renderView();
      });
  }

  _fetchPartnersByUserId() {
    if (AuthContextModel.model.userId > 0 && AppStateContext.model.appInitiated) {
      const userId = AuthContextModel.model.userId;
      const url = `${$http.instance.apiDomainURI}/integration/users/${userId}/partners`;

      if (AuthContextModel.model.hasSalesOpsPermissions) {
        this.order.actionOptionsLocked.push(ORDER_TYPE_OPTIONS.REDUCTION);
      } else {
        this.order.actions[ORDER_TYPE_OPTIONS.REDUCTION] && delete this.order.actions[ORDER_TYPE_OPTIONS.REDUCTION];
      }

      if (
        (AuthContextModel.model.isSalesOps || AuthContextModel.model.isRewrite) &&
        apiData.properties.rewrites?.enabled
      ) {
        this.order.actions[ORDER_TYPE_OPTIONS.REWRITE] = 'msg_rewrite_order';

        if (this.originalQuoteId) {
          this.order.actionOptionsLocked.push(ORDER_TYPE_OPTIONS.REWRITE);
        }
      }

      this.partnersFetching = true;
      this.renderView();

      $http.instance.api
        .get(url)
        .then(({ data }) => {
          this._init(data.content);
        })
        .catch(() => {
          this._init([]);
        })
        .finally(() => {
          this.partnersFetching = false;
        });

      return true;
    }
  }

  async fetchLocationBusinessContinuity(locationId) {
    if (this.bcFetchingForLocation === locationId) {
      return;
    }

    this.bcFetchingForLocation = locationId;
    this.isDataFetching = true;
    this.renderView();

    await this.order.asyncLoadLocationBusinessContinuity(locationId);

    this.bcFetchingForLocation = null;
    this.isDataFetching = false;
    this.renderView();
  }

  async fetchSwitchvoxSipStation(billingDealerId, customerId) {
    this.isDataFetching = true;
    this.renderView();

    await this.order.asyncLoadSwitchvoxSipStation({ billingDealerId, customerId });

    this.isDataFetching = false;
    this.renderView();
  }

  async fetchPartnerInfo(billingDealerId) {
    this.isDataFetching = true;
    this.renderView();

    await this.order.asyncLoadPartnerInfo({ billingDealerId });

    this.isDataFetching = false;
    this.renderView();
  }

  async fetchIsNewLocationFinalized(locationId = this.order.selectedLocationId) {
    if (locationId === null) {
      this.order.isNewLocationFinalized = false;
    } else {
      this.isDataFetching = true;
      this.renderView();

      try {
        await this.order.asyncLoadIsNewLocationFinalized(locationId);
      } catch (e) {
        if (config.showConsoleLog) {
          console.error(e);
        }
      } finally {
        this.isDataFetching = false;
      }
    }

    this.order.updateValidFields();
    this.renderView();
  }

  async fetchIsStandaloneServiceNonUCaaS(locationId = this.order.selectedLocationId) {
    if (locationId === null) {
      this.order.standaloneServiceNonUCaaS = false;
    } else {
      this.isDataFetching = true;
      this.renderView();

      try {
        await this.order.asyncLoadIsStandaloneServiceNonUCaaS(locationId);
      } catch (e) {
        if (config.showConsoleLog) {
          console.error(e);
        }
      } finally {
        this.isDataFetching = false;
      }
    }

    this.order.updateValidFields();
    this.renderView();
  }

  async fetchIsSangomaCXStandalone(billingDealerId, customerId) {
    this.isDataFetching = true;
    this.renderView();

    await this.order.asyncLoadIsSangomaCXStandalone({ billingDealerId, customerId });

    this.isDataFetching = false;
    this.renderView();
  }

  get isDataFetching() {
    return this._dataFetchingCounter !== 0;
  }

  set isDataFetching(value) {
    if (value) {
      this._dataFetchingCounter++;
    } else {
      this._dataFetchingCounter--;
    }

    if (this._dataFetchingCounter < 0) {
      this._dataFetchingCounter = 0;
    }
  }

  get doInitialInputFocus() {
    if (this._initialInputFocusDone) {
      return false;
    }

    const doFocus =
      this.__inited &&
      !this.isDataFetching &&
      !this.partnersFetching &&
      !this.customersFetching &&
      !this.locationsFetching;

    if (doFocus) {
      this._initialInputFocusDone = true;
    }

    return doFocus;
  }

  defineLocationBusinessContinuity(locationId) {
    if (!locationId) {
      return;
    }

    if (this.order.locationBcHash.hasOwnProperty(locationId)) {
      this.order.onChange('bcQuote', this.order.locationBcHash[locationId]);

      return;
    }

    this.fetchLocationBusinessContinuity(locationId);
  }

  onOrderPropChange(prop, value) {
    const { selectedAction, selectedLocationId } = this.order;
    const addOnActionIndex = OrderInitiationController.quoteTypeIndexes['add-on'];

    const addOnLocationSelected = value && prop === 'selectedLocationId' && selectedAction === addOnActionIndex;
    const addOnSelectedForLocation =
      selectedLocationId && prop === 'selectedAction' && Number(value) === addOnActionIndex;

    if (addOnLocationSelected || addOnSelectedForLocation) {
      const locationId = addOnLocationSelected ? value : selectedLocationId;

      this.defineLocationBusinessContinuity(locationId);
    }

    if (prop === 'selectedLocationId') {
      this.fetchIsNewLocationFinalized(value);
      this.fetchIsStandaloneServiceNonUCaaS(value);
    }

    this.order.onChange(prop, value);
  }

  onChangeLocationIds(v) {
    this.order.onChange('selectedLocationIds', v);
  }

  onStartQuoteButtonClick() {
    const { originalQuoteId, resetOverrides } = this;
    const {
      selectedAction,
      selectedCustomerId,
      selectedLocationId,
      selectedLocationIds,
      selectedPartnerId,
      bcQuote,
      serviceType,
      selectedIndustryTypeId,
    } = this.order;

    const type = OrderInitiationController.quoteTypes[selectedAction];
    const url = [];
    const search = ['serviceType=' + serviceType];

    if (bcQuote) {
      search.push('bc=1');
    }

    if (originalQuoteId) {
      url.push('originalId', originalQuoteId);
    }

    if (selectedPartnerId) {
      url.push('partners', selectedPartnerId);
    }

    if (selectedCustomerId) {
      url.push('customers', selectedCustomerId);
    }

    switch (type) {
      case 'new-customer':
        url.push(type);
        search.push('industryCodeId=' + selectedIndustryTypeId);
        break;
      case 'new-locations':
        url.push('locations', 'new');
        break;
      case 'add-on':
        url.push('locations', selectedLocationId, type);
        break;
      case 'rewrite':
        url.push(type);
        search.push('locationIds=' + selectedLocationIds.join(','));
        break;
      default:
        break;
    }

    AppRouter.push({
      pathname: '/' + url.join('/'),
      state: { resetOverrides },
      search: search.length ? '?' + search.join('&') : '',
    });
  }
}

export default OrderInitiationController;
