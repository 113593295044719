import config from '../config';
import $http from '../common/$http';

const TrackingApi = {
  async numLocationsExceeded(customerId, numLocations, numberOfLocationsWithTrackingId) {
    const numOfNewLocations = numLocations - numberOfLocationsWithTrackingId;

    if (numOfNewLocations === 0) {
      return false;
    }

    const url = [
      config.api.url,
      '/tracking/location/locationcount/?',
      ['customerId=' + encodeURIComponent(customerId), 'numLocations=' + encodeURIComponent(numOfNewLocations)].join(
        '&'
      ),
    ].join('');

    try {
      const r = await $http.instance.apiWithoutGlobalHandler.get(url);

      if (r.status === 200) {
        return !r.data.content.success;
      }

      return false;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async quoteOperationState(quoteId) {
    const url = config.api.url + '/quote/quotes/' + encodeURIComponent(quoteId) + '/quoteOperationState';

    try {
      const r = await $http.instance.apiWithoutGlobalHandler.get(url);

      return r.data.content;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

export default TrackingApi;
