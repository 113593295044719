import React, { Component, PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as UUID } from 'uuid';
import AbstractControl from '../AbstractControl';
import '../css/select-input.css';
import AutocompleteValue from '../../../common/helpers/AutocompleteValue';
import bindMultiple from '../../../common/helpers/bindMultiple';
import { getTranslateString } from '../../../common/helpers/getTranslateString';

class OptionItem extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    handleFindByTextInput: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.bool,
    error: PropTypes.number,
  };

  static defaultProps = {
    text: '',
    value: 0,
  };

  constructor(props) {
    super(props);

    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
  }

  handleInputKeyUp(event) {
    if (typeof this.props.handleFindByTextInput === 'function') {
      this.props.handleFindByTextInput(event);
    }
  }

  render() {
    const { text, value, onClick, selected, error } = this.props;

    const optionClassName = classNames({
      'select-option': true,
      'selected-option': selected,
      error: error > 0,
    });
    const id = 'option-item-' + value;

    return (
      <div
        tabIndex="0"
        id={id}
        title={text}
        className={optionClassName}
        data-value={value}
        onClick={onClick}
        onKeyUp={this.handleInputKeyUp}
      >
        {text}
      </div>
    );
  }
}

class MultiSelectControl extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
    onBlur: PropTypes.func,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    clearInvalidOnBlur: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    clearInvalidOnBlur: true,
    isReadOnly: false,
  };

  inputElem = null;
  dropDownElem = null;
  inputWrapper = null;
  dropDownWrapper = null;

  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
    };

    this.abstractComponentRef = null;
    this._searchValue = '';
    this._searchValueResetTimeout = null;
    this._foundEl = null;
    this.inputAutocompleteValue = AutocompleteValue();
    this.inputNameValue = 'input-' + UUID();

    bindMultiple(
      this,
      this.__getSelectedOptionsField,
      this.__getSelectedOptionsText,
      this.__getSelectedOptionsValue,
      this.handleOnFocus,
      this.handleOnChange,
      this.handleSelectOption,
      this.handleInputKeyUp,
      this.handleOptionsKeyDown,
      this.openDropDownElem,
      this.closeDropDownElem,
      this.handleOnFocusout,
      this.handleFindByTextInput,
      this.handleAbstractComponentRefSet
    );
  }

  __getSelectedOptionsField(value, field) {
    return this.props.options
      .filter(o => value.includes(o.value))
      .reduce((result, _value) => {
        result.push(_value[field]);

        return result;
      }, []);
  }

  __getSelectedOptionsText(value) {
    return this.__getSelectedOptionsField(value, 'text').join(', ');
  }

  __getSelectedOptionsValue(value) {
    return this.__getSelectedOptionsField(value, 'value');
  }

  static getDerivedStateFromProps(props) {
    if (props.options?.length) {
      const options = props.options.map(option => {
        if (option.text.substr(0, 4) === 'msg_') {
          option.text = props.intl.formatMessage({ id: option.text });
        }

        return option;
      });

      return {
        optionList: options,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { value } = this.props;

    const abstractComponentNode = this.abstractComponentRef.current;

    this.inputWrapper = abstractComponentNode.querySelector('.select-input-component');
    this.dropDownWrapper = abstractComponentNode.querySelector('.select-option-list');

    this.inputWrapper.addEventListener('focusout', this.handleOnFocusout);
    this.dropDownWrapper.addEventListener('focusout', this.handleOnFocusout);

    if (value && this.inputElem) {
      this.inputElem.value = this.__getSelectedOptionsText(value);
    }
  }

  getSnapshotBeforeUpdate() {
    const { value, options } = this.props;

    if (value === null || (options && value.length === 0)) {
      return '';
    } else if (options && value.length > 0) {
      return this.__getSelectedOptionsText(value);
    } else {
      return this.inputElem.value;
    }
  }

  componentDidUpdate(prevProps, state, value) {
    this.inputElem.value = value;

    if (prevProps.focus && !this.props.focus) {
      this.inputElem.blur();
    }
  }

  componentWillUnmount() {
    this.inputWrapper.removeEventListener('focusout', this.handleOnFocusout);
    this.dropDownWrapper.removeEventListener('focusout', this.handleOnFocusout);
  }

  closeDropDownElem() {
    this.dropDownElem.style.display = 'none';
    this._foundEl = null;

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  handleOnFocusout(e) {
    if (e.relatedTarget === null || e.relatedTarget.parentElement.className !== this.dropDownElem.className) {
      this.closeDropDownElem();
    }
  }

  openDropDownElem() {
    this.dropDownElem.style.display = 'block';
  }

  handleOnFocus() {
    this.openDropDownElem();
    let parentPosition = this.dropDownElem.parentNode.getBoundingClientRect();
    let listHeight = this.dropDownElem.offsetHeight;
    let toBottom = window.innerHeight - parentPosition.bottom;
    // 130px it's a height of the toolbar (fixed top)
    let toTop = parentPosition.top - 130;

    if (toBottom < listHeight && parentPosition.top > toBottom && toTop > listHeight) {
      this.dropDownElem.style.top = '';
      this.dropDownElem.style.bottom = `${parentPosition.height}px`;
    } else {
      this.dropDownElem.style.top = '100%';
      this.dropDownElem.style.bottom = '';
    }
  }

  handleOnChange(value) {
    !this.props.lockedState && !this.props.disabledState && this.props.onChange(value);
  }

  handleSelectOption(event) {
    const selectedTextValue = event ? event.target.textContent : this._foundEl.textContent;
    const selectedOption = this.props.options.find(o => {
      return o.text.toLowerCase().trim() === selectedTextValue.toLowerCase().trim();
    });

    let selectedOptions = this.__getSelectedOptionsValue(this.props.value);

    const idx = selectedOptions.indexOf(selectedOption.value);

    if (idx === -1) {
      selectedOptions.push(selectedOption.value);
    } else {
      selectedOptions.splice(idx, 1);
    }

    this.inputElem.value = this.__getSelectedOptionsText(this.props.value);
    this.handleOnChange(selectedOption.value);
  }

  handleOptionsKeyDown(event) {
    const key = event.key;
    const element = event.target;

    switch (key) {
      case 'ArrowDown':
        element.nextElementSibling ? element.nextElementSibling.focus() : this.dropDownElem.firstChild.focus();
        event.preventDefault();
        break;
      case 'ArrowUp':
        element.previousElementSibling ? element.previousElementSibling.focus() : this.inputElem.focus();
        event.preventDefault();
        break;
      case 'Enter':
        this.handleSelectOption(event);
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleInputKeyUp(event) {
    const key = event.key;

    switch (key) {
      case 'ArrowDown':
        if (this.dropDownElem.firstChild) {
          this.dropDownElem.firstChild.focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (this.dropDownElem.lastChild) {
          this.dropDownElem.lastChild.focus();
        }
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      case 'Enter':
        if (this.state.optionList.length === 1) {
          let option = this.state.optionList[0];
          if (option.text.toLowerCase().trim() === event.target.value.toLowerCase().trim()) {
            this.inputElem.value = option.text;
            this.inputElem.blur();
          }
        }

        if (this._foundEl) {
          this.handleSelectOption();
        }

        event.preventDefault();
        break;
      default:
        this.handleFindByTextInput(event);
        break;
    }
  }

  handleFindByTextInput(event) {
    const { key } = event;

    if (key.length === 1 && key.match(/[\w!@#$%^&()-_—:;' "]/g)) {
      this.changeSearchValue(key.toLowerCase());
      const option = this.state.optionList.find(
        opt => opt.name.toLowerCase().substring(0, this._searchValue.length) === this._searchValue
      );

      if (option) {
        this.focusFoundOption(option.value);
        event.preventDefault();
      }
    }
  }

  changeSearchValue(v) {
    if (this._searchValueResetTimeout !== null) {
      clearTimeout(this._searchValueResetTimeout);
      this._searchValueResetTimeout = null;
    }

    this._searchValue += v;

    this._searchValueResetTimeout = setTimeout(() => {
      this._searchValue = '';
    }, 700);
  }

  focusFoundOption(optionValue) {
    const optionSelector = '#option-item-' + optionValue;
    this._foundEl = this.abstractComponentRef.current.querySelector(optionSelector);

    this._foundEl.focus();
    this._foundEl.scrollIntoView();
  }

  render() {
    const {
      // Props for current component
      id,
      value,
      lockedState,
      disabledState,
      className,
      fullWidth,
      placeholder,
      intl,
      ariaLabel,
      isLoading,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      required,
      errorText,
      requiredBlue,
      tooltipText,
      parentClassName,
    } = this.props;

    const componentClassName = classNames('select-input-component', 'multi', className);
    const optionListClassName = classNames({
      'select-option-list': true,
      'full-width-option-list': fullWidth,
    });

    const inputAbstractProps = {
      hint,
      style,
      focus,
      label,
      lockedState,
      disabledState,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      className: componentClassName,
      labelFor: id,
    };

    const placeholderTextStr =
      typeof placeholder === 'string'
        ? getTranslateString(placeholder, intl)
        : placeholder?.props && intl.formatMessage({ id: placeholder.props.id });

    const ariaLabelString =
      typeof ariaLabel === 'string' && ariaLabel.substr(0, 4) === 'msg_'
        ? intl.formatMessage({ id: ariaLabel })
        : ariaLabel;

    const iconClass = classNames({
      'field-icon': true,
      loader: isLoading,
    });

    return (
      <AbstractControl {...inputAbstractProps} forwardWrapperRef={this.handleAbstractComponentRefSet}>
        <React.Fragment>
          <span className={iconClass} />
          <input
            autoComplete={this.inputAutocompleteValue}
            name={this.inputNameValue}
            type="select"
            id={id}
            ref={elem => (this.inputElem = elem)}
            disabled={lockedState || disabledState}
            placeholder={placeholderTextStr || ''}
            onChange={this.fieldOnChange}
            onFocus={this.handleOnFocus}
            onKeyUp={this.handleInputKeyUp}
            aria-label={ariaLabelString}
            readOnly
          />

          <div className="icon-expand-more field-icon" />

          <div
            className={optionListClassName}
            onFocus={this.openDropDownElem}
            onKeyDown={this.handleOptionsKeyDown}
            ref={el => (this.dropDownElem = el)}
          >
            {this.state.optionList.map(option => (
              <OptionItem
                {...option}
                key={id + '-option-' + option.id}
                selected={value.includes(option.value)}
                onClick={this.handleSelectOption}
                handleFindByTextInput={this.handleFindByTextInput}
              />
            ))}
          </div>
        </React.Fragment>
      </AbstractControl>
    );
  }

  handleAbstractComponentRefSet(ref) {
    this.abstractComponentRef = ref;
  }
}

export default injectIntl(MultiSelectControl);
