import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ToggleControl } from '../../Controls';
import { ORDER_TYPES } from '../../../common/enums';
import { AuthContextModel, PartnerContextModel } from '../../../Context';

class OvernightGuaranteeToggle extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    orderType: PropTypes.string,
    isPurchase: PropTypes.bool,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { value, lockedState, disabledState, orderType, isPurchase, onChange } = this.props;
    const { isWholeSale } = PartnerContextModel;
    const { isSalesOps } = AuthContextModel;

    // KM-9752: Rewrite of visibility and disabled state rules
    let isToggleActive = isSalesOps;

    if (!isToggleActive && isWholeSale && orderType === ORDER_TYPES.NEW_CUSTOMER && isPurchase) {
      isToggleActive = true;
    }

    return (
      <div className="overnight-toggle-component">
        <ToggleControl
          id="overnightGuarantee"
          hint="msg_only_purchase_quotes_may_elect_to_waive"
          label="msg_overnight_no_charge_replacement_guarantee"
          key="overnightGuaranteeToggleControl"
          lockedState={lockedState}
          disabledState={!isToggleActive || disabledState}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default OvernightGuaranteeToggle;
