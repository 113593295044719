import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Dialog from '../Dialog';
import { CheckboxControl, SelectControl } from '../Controls';
import apiData from '../../Storage/apiData';
import './css/add-line-items-dialog.css';
import { SortArrayOfObjects } from '../../common/helpers/SortArrayOfObjects';
import translator from '../../common/translate';
import bindMultiple from '../../common/helpers/bindMultiple';
import BadgeButton from '../BadgeButton';
import config from '../../config';
import { AuthContext } from '../../Context';
import formatter from '../../common/helpers/format/formatter';
import { TextInput } from '../Inputs';
import { TemplateStringReplace } from '../../common/helpers/format';

const GROUP_BY_MAPPING = [
  {
    alias: 'ungrouped',
    text: 'Ungrouped',
    value: 1,
  },
  {
    alias: 'brandName',
    text: 'Brand Name',
    value: 2,
  },
  {
    alias: 'lineItemSubCategoryName',
    text: 'Type',
    value: 3,
  },
];

const dialogProps = {
  modal: true,
  titleClassName: 'dialog-header-left',
  modalClassName: 'add-line-items-dialog',
  bodyClassName: 'add-line-items-dialog-content-body',
  contentClassName: 'add-line-items-dialog-content-frame',
  actionsContainerClassName: 'add-line-items-actions-frame',
  dialogWithErrors: true,
};

const GROUP_BY = {
  ungrouped: 'ungrouped',
  brandName: 'brandName',
  subCategory: 'lineItemSubCategoryName',
};

const ORDER_BY = {
  lineItemName: 'lineItemName',
  brandName: 'brandName',
  subCategory: 'lineItemSubCategoryName',
  dealerNet: 'dealerNet',
};

class AddLineItems extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    quoteLineItemIds: PropTypes.arrayOf(PropTypes.number),
    onDialogClose: PropTypes.func,
    onAddButtonClick: PropTypes.func,
  };

  constructor() {
    super(...arguments);

    this.state = {
      searchString: '',
      groupBy: GROUP_BY.subCategory,
      orderBy: ORDER_BY.lineItemName,
      orderByDirection: 1,
      selectedLineItemIds: [],
    };

    bindMultiple(
      this,
      this.handleSearchStringChange,
      this.handleGroupByChange,
      this.handleOrderByChange,
      this.handleLineItemClick,
      this.handleAddButtonClick
    );
  }

  handleAddButtonClick() {
    if (typeof this.props.onAddButtonClick === 'function') {
      this.props.onAddButtonClick(this.state.selectedLineItemIds);
    }
  }

  handleSearchStringChange(value) {
    this.setState({
      searchString: value,
    });
  }

  handleGroupByChange(value) {
    const mapping = GROUP_BY_MAPPING.find(d => d.value === value);

    if (mapping) {
      this.setState({
        groupBy: mapping.alias,
      });
    }
  }

  handleOrderByChange(value) {
    if (value === this.state.orderBy) {
      return this.setState({
        orderByDirection: this.state.orderByDirection * -1,
      });
    }

    return this.setState({
      orderBy: value,
      // Reset to ASC direction on field switch
      orderByDirection: 1,
    });
  }

  handleLineItemClick(lineItemId) {
    const selectedLineItemIds = this.state.selectedLineItemIds;

    if (selectedLineItemIds.includes(lineItemId)) {
      const index = selectedLineItemIds.indexOf(lineItemId);
      selectedLineItemIds.splice(index, 1);
    } else {
      selectedLineItemIds.push(lineItemId);
    }

    this.setState({
      selectedLineItemIds,
    });
  }

  renderList() {
    let lineItems = this.props.lineItems;

    if (this.state.searchString.length) {
      lineItems = lineItems.filter(
        lineItemApiData =>
          lineItemApiData.lineItemName.toLowerCase().indexOf(this.state.searchString.toLowerCase()) !== -1 ||
          this.state.selectedLineItemIds.includes(lineItemApiData.lineItemId)
        // TODO: Should we always show already added line items?
        // || this.props.quoteLineItemIds.includes(lineItemApiData.lineItemId)
      );
    }

    const rcItems = [];
    const nrcItems = [];

    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];

      if (lineItem._isRecurring) {
        rcItems.push(lineItem);
      } else {
        nrcItems.push(lineItem);
      }
    }

    const structuredData = [];

    if (nrcItems.length) {
      structuredData.push({
        label: 'Non-Recurring',
        key: 'nrc',
        categories: this.getCategories(nrcItems),
      });
    }

    if (rcItems.length) {
      structuredData.push({
        label: 'Recurring',
        key: 'rc',
        categories: this.getCategories(rcItems),
      });
    }

    return (
      <div className="line-items-catalog">
        <ul className="list">
          {structuredData.map(section => (
            <li id={section.key} key={section.key} className="section item">
              <label>{section.label}</label>
              <ul className="list">
                {section.categories.map(category => (
                  <li
                    id={[section.key, category.key].join('-')}
                    key={[section.key, category.key].join('-')}
                    className="category item"
                  >
                    <label>{category.label}</label>
                    <ul className="list">
                      {category.groups.map(group => (
                        <li
                          id={[section.key, category.key, group.key].join('-')}
                          key={[section.key, category.key, group.key].join('-')}
                          className="group item"
                        >
                          <label>{group.label}</label>
                          <ul className="list">
                            {group.lineItems.map(lineItem => {
                              const key = [section.key, category.key, group.key, lineItem.lineItemId].join('-');
                              const classNames = ['item', 'device'];
                              const isAdded = this.props.quoteLineItemIds.includes(lineItem.lineItemId);
                              const isSelected = this.state.selectedLineItemIds.includes(lineItem.lineItemId);
                              const disabledState = isAdded || lineItem._forceDisableItemSelection;
                              const onClickHandler = !disabledState
                                ? this.handleLineItemClick.bind(this, lineItem.lineItemId)
                                : () => {};

                              if (isAdded) {
                                classNames.push('is-added');
                              }

                              if (isSelected) {
                                classNames.push('is-selected');
                              }

                              return (
                                <li id={key} key={key} className={classNames.join(' ')}>
                                  <span className="checkbox-block">
                                    <CheckboxControl
                                      id={'line-item-checkbox-' + lineItem.lineItemId}
                                      onChange={onClickHandler}
                                      value={isSelected || isAdded}
                                      disabledState={disabledState}
                                    />
                                  </span>
                                  <span className="line-item-name" onClick={onClickHandler}>
                                    {lineItem.lineItemName}
                                  </span>
                                  <span
                                    onClick={onClickHandler}
                                    className={
                                      'delaer-net' + (!lineItem.dealerNet && lineItem._bundleDealerNet ? ' bundle' : '')
                                    }
                                  >
                                    {formatter(lineItem.dealerNet || lineItem._bundleDealerNet)}
                                  </span>
                                  <span className="slick-link">
                                    {lineItem.pdfPath ? (
                                      <a
                                        onClick={e => e.stopPropagation()}
                                        href={
                                          config.api.url + '/' + lineItem.pdfPath + '?token=' + AuthContext.model.token
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="slick-pdf icon-pdf" />
                                      </a>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  getCategories(lineItems) {
    const categories = [];

    // Look for categories for selected scope of items
    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];
      const categoryData = apiData.categories.find(category => category.id === lineItem.lineItemCategoryId);
      let categoryIndex = categories.findIndex(category => category.id === categoryData.id);

      if (categoryIndex === -1) {
        categoryIndex =
          categories.push({
            id: categoryData.id,
            key: 'category-' + categoryData.id,
            sequence: categoryData.sequence,
            label: categoryData.name,
            lineItems: [],
          }) - 1;
      }

      categories[categoryIndex].lineItems.push(lineItem);
    }

    // Sort categories by sequence
    SortArrayOfObjects(categories, 'sequence');

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];

      category.groups = this.getGroups(category.lineItems);

      delete category.lineItems;
    }

    return categories;
  }

  getGroups(lineItems) {
    let groupKey = 'ungrouped';

    if (this.state.groupBy === GROUP_BY.ungrouped) {
      SortArrayOfObjects(lineItems, this.state.orderBy, this.state.orderByDirection);

      return [
        {
          label: 'Ungrouped',
          key: groupKey,
          lineItems,
        },
      ];
    }

    const groups = [];
    const getGroupIndex = (groups, groupKey) => groups.findIndex(group => group.key === groupKey);

    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];
      let groupLabel = '';
      let sequence = 0;

      if (this.state.groupBy === GROUP_BY.subCategory) {
        const subCategoryData = apiData.categories
          .find(category => category.id === lineItem.lineItemCategoryId)
          .subCategories.find(subCategory => subCategory.id === lineItem.lineItemSubCategoryId);

        groupLabel = subCategoryData.name;
        groupKey = 'group-' + subCategoryData.id;
        sequence = subCategoryData.sequence;
      }

      if (this.state.groupBy === GROUP_BY.brandName) {
        const brandName = apiData.brandNames.find(brandNameObj => brandNameObj.id === lineItem.brandNameId);

        groupLabel = brandName.description;
        sequence = brandName.sequence;
        groupKey = 'group-' + brandName.id;
      }

      let groupIndex = getGroupIndex(groups, groupKey);

      if (groupIndex === -1) {
        groupIndex =
          groups.push({
            label: groupLabel,
            key: groupKey,
            lineItems: [],
            sequence,
          }) - 1;
      }

      groups[groupIndex].lineItems.push(lineItem);
    }

    SortArrayOfObjects(groups, 'sequence');

    // Sort items inside groups
    for (let g = 0; g < groups.length; g++) {
      const group = groups[g];

      SortArrayOfObjects(group.lineItems, this.state.orderBy, this.state.orderByDirection);
    }

    return groups;
  }

  renderSortingBar() {
    const items = [
      {
        label: 'Name',
        field: ORDER_BY.lineItemName,
      },
      {
        label: 'Price',
        className: 'align-right',
        field: ORDER_BY.dealerNet,
      },
      {
        label: 'Slick',
        className: 'inactive',
      },
    ];

    return (
      <ul className="sorting-bar align-left">
        {items.map(d => {
          const key = 'add-line-item-sorting-bar-' + d.label.toLowerCase().replaceAll(/\s+/g, '_');
          const classNames = ['option'];

          if (d.className) {
            classNames.push(d.className);
          }

          if (this.state.orderBy === d.field) {
            classNames.push(this.state.orderByDirection > 0 ? 'asc' : 'desc');
          }

          return (
            <li
              id={key}
              key={key}
              className={classNames.join(' ')}
              onClick={d.field ? this.handleOrderByChange.bind(this, d.field) : null}
            >
              {d.label}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const groupByMapping = GROUP_BY_MAPPING.find(d => d.alias === this.state.groupBy);
    let addButtonLabel = translator.getMessage('msg_add');

    if (this.state.selectedLineItemIds.length === 1) {
      addButtonLabel = translator.getMessage('msg_add_one_item');
    }

    if (this.state.selectedLineItemIds.length > 1) {
      addButtonLabel = TemplateStringReplace(this.props.intl.formatMessage({ id: 'msg_add_x_items' }), {
        itemsCount: this.state.selectedLineItemIds.length,
      });
    }

    return (
      <Dialog
        {...dialogProps}
        onRequestClose={this.props.onDialogClose}
        title={<header className="text-ellipsis">Add Line Item</header>}
        isOpen={this.props.isOpen}
        actions={[
          <button
            key="quote-devices-modal-cancel-button"
            id="quote-devices-modal-cancel-button"
            className="btn-cancel text-btn"
            onClick={this.props.onDialogClose}
          >
            {translator.getMessage('msg_cancel')}
          </button>,
          <BadgeButton
            key="quote-devices-modal-add-button"
            id="quote-devices-modal-add-button"
            btnClassName="btn-add primary"
            onClick={this.handleAddButtonClick}
            disabled={this.state.selectedLineItemIds.length === 0}
            infoTooltip={
              !this.state.selectedLineItemIds.length ? 'msg_add_line_item_dialog_add_button_no_items_tooltip' : null
            }
            label={addButtonLabel}
            tooltipAlign="left"
            tooltipVerticalAlign={'top'}
            hideInfoBadge
          />,
        ]}
      >
        <div className="add-line-items-dialog-filters">
          <div className="filter-item search">
            <TextInput
              id="add-line-items-dialog-search-input"
              className="search-input"
              placeholder="msg_search_for_a_model_or_type"
              value={this.state.searchString}
              onChange={this.handleSearchStringChange}
              focus={true}
            />
          </div>
          <div className="filter-item group-by">
            <label>{translator.getMessage('msg_group_by')}</label>
            <SelectControl
              id="groupByField"
              className="group-by-select"
              parentClassName="group-by-select-component"
              value={groupByMapping.value}
              options={GROUP_BY_MAPPING}
              onChange={this.handleGroupByChange}
            />
          </div>
        </div>

        {this.renderSortingBar()}

        <div className="line-items-catalog-wrapper">{this.props.lineItems.length ? this.renderList() : null}</div>
      </Dialog>
    );
  }
}

export default injectIntl(AddLineItems);
